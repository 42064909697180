export const AUTH_REQUEST = 'AUTH_REQUEST'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_ERROR = 'AUTH_ERROR'
export const REQUEST_LOADING = 'REQUEST_LOADING'
export const REQUEST_SUCCESS = 'REQUEST_SUCCESS'
export const REQUEST_ERROR = 'REQUEST_ERROR'
export const SET_LOGIN_STATE = 'SET_LOGIN_STATE'
export const SET_PROFILE_STATE = 'SET_PROFILE_STATE'
export const SET_STAY_LOGGED_IN_STATE = 'SET_STAY_LOGGED_IN_STATE'
export const SET_USER_STATE = 'SET_USER_STATE'
export const RESET_ERROR_STATES = 'RESET_ERROR_STATES'
export const SET_SELECTED_INDIKATOREN = 'SET_SELECTED_INDIKATOREN'
export const SET_SELECTED_NK_INDIKATOREN = 'SET_SELECTED_NK_INDIKATOREN'
export const SET_INFO_STATE = 'SET_INFO_STATE'
export const SET_JWT_STATE = 'SET_JWT_STATE'
export const SET_SYNC_PROGRESS_STEPS_TOTAL = 'SET_SYNC_PROGRESS_STEPS_TOTAL'
export const SET_SELECTED_KONTROLLE_ID = 'SET_SELECTED_KONTROLLE_ID'
export const SAVE_KONTROLLE_STATE = 'SAVE_KONTROLLE_STATE'
export const SAVE_HERDE_STATE = 'SAVE_HERDE_STATE'
export const SAVE_HERDE_VORLAGE_STATE = 'SAVE_HERDE_VORLAGE_STATE'
export const SAVE_HERDE_PRESET_STATE = 'SAVE_HERDE_PRESET_STATE'
export const SAVE_ANTWORT_STATE = 'SAVE_ANTWORT_STATE'
export const SAVE_TIER_STATE = 'SAVE_TIER_STATE'
export const SET_CURRENT_TIER = 'SET_CURRENT_TIER'
export const SET_MEDIA_FILE_LIST = 'SET_MEDIA_FILE_LIST'
export const MEDIA_FILES_REQUEST = 'MEDIA_FILES_REQUEST'
export const MEDIA_FILES_SUCCESS = 'MEDIA_FILES_SUCCESS'
export const MEDIA_FILE_SUCCESS = 'MEDIA_FILE_SUCCESS'
export const MEDIA_FILES_ERROR = 'MEDIA_FILES_ERROR'
