/* global cordova device */
import { mapGetters, mapState } from 'vuex'

import Tier from '@/store/modules/api/models/Tier'
import Herde from '@/store/modules/api/models/Herde'
import IndikatorModel from '@/store/modules/api/models/Indikator'
import HerdePreset from '@/store/modules/api/models/HerdePreset'
import IndikatorGruppe from '@/store/modules/api/models/IndikatorGruppe'
import Kontrolle from '@/store/modules/api/models/Kontrolle'
import Info from '@/store/modules/api/models/Info'
import Ursachenanalyse from '@/store/modules/api/models/Ursachenanalyse'

import {
  CONTROL_STATE_CREATED,
  CONTROL_STATE_FINISHED,
  CONTROL_STATE_INTERRUPTED,
  CONTROL_TYPE_FLEXIBLE,
  CONTROL_TYPE_TEATS,
  createMediaFileDirectory,
  fetchMediaFile,
  getMediaFileList,
  INDIKATOR_GRUPPE_UUID_EINZELKUH,
  INDIKATOR_GRUPPE_UUID_PRODUKTIONSSYSTEM,
  STATUS_SYNC_INITIAL,
  STATUS_SYNC_KONTROLLE_FINISHED,
  writeMediaFile
} from '@/store/utils'

import marked from 'marked'
import { APP_MODE_APP, APP_MODE_DESKTOP } from './configuration'

/**
 * Convert MySQL date string to JS date
 * @see http://www.codingmusician.com/2019/12/converting-mysql-utc-time-to-users-local-time-with-javascript/
 * @param str
 * @return {Date}
 */
const mysql2JsDate = (str) => {
  const t = str.split(/[- :]/)
  return new Date(t[0], t[1] - 1, t[2], t[3], t[4], t[5])
}

const mixins = {
  computed: {
    /**
     * Map base state
     */
    ...mapState([
      'appMode'
    ]),
    /**
     * Map help getters
     */
    ...mapGetters('help', [
      'messages',
      'translateState'
    ]),
    /**
     * Map api state
     */
    ...mapState('api', [
      'mediaFileList',
      'mediaFileDirectoryName'
    ]),
    /**
     * Map api getters
     */
    ...mapGetters('api', [
      'synchronisableEndpoints',
      'hasRequestError',
      'mediaFileDirectoryUrl',
      'kontrollen',
      'kontrolle',
      'indikatorGruppen',
      'antworten'
    ]),
    /**
     * Is app mode "app"
     * @return {boolean}
     */
    isAppMode () {
      return (this.appMode === APP_MODE_APP)
    },
    /**
     * Is app mode "desktop"
     * @return {boolean}
     */
    isDesktopMode () {
      return (this.appMode === APP_MODE_DESKTOP)
    },
    /**
     * Get all "Tiere" with "Antworten" by "kontrolle_id"
     * @return {Collection<InstanceOf<Tier>>|*[]}
     */
    getTiereByControlId () {
      if (!this.kontrolle) {
        return []
      }

      const id = this.kontrolle.id
      return Tier.query()
        .where('kontrolle_id', id)
        // .orWhere('kontrolle_id', this.kontrolle.hauptkontrolle_id)
        .with('antworten', (query) => {
          query.where('kontrolle_id', id)
        })
        .get()
    },
    /**
     * Get sum of added "Tiere"
     * @return {number}
     */
    sumAdded () {
      return this.getTiereByControlId.length
    },
    /**
     * Get sum of "Herde.stichprobe"
     * @return {number}
     */
    sumTotal () {
      if (!this.kontrolle || !Herde.exists()) {
        return 0
      }

      const model = (this.kontrolle.herde_id)
        ? Herde.query()
          .find(this.kontrolle.herde_id)
        : HerdePreset.query()
          .last()

      return (model && model.stichprobe) ? model.stichprobe : 0
    },
    /**
     * Get all "Zitzenkontrollen"
     * @return {Collection<InstanceOf<Kontrolle>>}
     */
    teatsControls () {
      return Kontrolle.query()
        .where('typ', (value) => value === CONTROL_TYPE_TEATS)
        .orderBy('benutzer_erstellt_am', 'desc')
        .all()
    },
    /**
     * Is interrupted control
     * @return {boolean}
     */
    interruptedControl () {
      return (this.kontrollen.length)
        ? this.kontrollen.find(control => !this.isControlDisabled(control) &&
                                          (control.status === CONTROL_STATE_CREATED || control.status === CONTROL_STATE_INTERRUPTED))
        : false
    },
    /**
     * Detect app build mode = debug
     * @return {boolean}
     */
    isDebugMode () {
      return (process.env.VUE_APP_BUILD_MODE === 'debug')
    },
    /**
     * Returns an array of Kuerzeln extracted from Produktionssystem -> Indikatoren -> fragen -> Antworten
     */
    produktionSystemKuerzeln () {
      const psk = []
      const produktionssystemAntworten = this.getProduktionssystemAntworten()

      produktionssystemAntworten.forEach((antwort) => {
        const shortName = JSON.parse(antwort.json)
        if (!psk.includes(shortName)) {
          psk.push(shortName)
        }
      })
      return psk
    },
    controlErgebnisse () {
      if (!this.kontrolle) {
        return false
      }
      return this.kontrolle.ergebnisse
    }
  },
  methods: {
    getUrsachenXkontrolle (ursacheIndikatorId, kontrolleIndikatorIds) {
      const ursachenanalyse = Ursachenanalyse.query()
        .where('ursache_indikator_id', ursacheIndikatorId)
        .where('kontrolle_indikator_id', kontrolleIndikatorIds)
        .all()

      if (!ursachenanalyse) {
        return false
      }

      return ursachenanalyse
    },
    getUrsachenanalyseRang (ursacheIndikatorId, kontrolleIndikatorIds) {
      const ursachenanalyse = Ursachenanalyse.query()
        .where('ursache_indikator_id', ursacheIndikatorId)
        .where('kontrolle_indikator_id', kontrolleIndikatorIds)
        .all()

      if (!ursachenanalyse) {
        return false
      }

      return ursachenanalyse.reduce((a, b) => Math.min(a, b.rang), 9999)
    },
    indikatorIsDangerOrWarning (indikatorId) {
      const indikatorData = this.getIndikatorData(indikatorId)
      return indikatorData && (indikatorData.state === 'danger' || indikatorData.state === 'warning')
    },
    getIndikatorData (indikatorId) {
      let output = false
      this.controlErgebnisse.forEach((indikatorData) => {
        if (indikatorData.indikatorId === indikatorId) {
          output = indikatorData
        }
      })
      return output
    },
    /**
     * Get indikator by id
     * @param indikatorId
     * @return {Item<InstanceOf<Indikator>>}
     */
    getIndikatorById (indikatorId) {
      return IndikatorModel.query()
        .whereId(indikatorId)
        .first()
    },
    /**
     * Returns all Produktionssystem -> Indikatoren -> fragen -> Antworten
     */
    getProduktionssystemAntworten () {
      const gruppe = IndikatorGruppe.query()
        .whereId(INDIKATOR_GRUPPE_UUID_PRODUKTIONSSYSTEM)
        .with('indikatoren.fragen.antworten', (query) => {
          query.where('kontrolle_id', this.kontrolle.id)
        })
        .first()

      if (!gruppe) {
        return []
      }

      const indikatoren = gruppe.indikatoren
      const fragen = indikatoren.map((indikator) => indikator.fragen).flat()
      return fragen.map((frage) => frage.antworten).flat()
    },
    /**
     * Is control a "Nachkontrolle". the double !! operator casts a boolean
     * @return {boolean}
     */
    isNachkontrolle (control) {
      return !!control.hauptkontrolle_id
    },
    /**
     * Get "Indikatoren" by group id
     * @param id
     * @return {*}
     */
    getIndikatorenByGroupId (id) {
      const gruppe = this.indikatorGruppen.find(g => g && g.id === id)
      if (!gruppe) {
        return []
      }

      const controlIsFlexible = this.kontrolle.typ === CONTROL_TYPE_FLEXIBLE
      const selectedIndikatoren = this.$store.state.api.selectedIndikatoren
      const selectedNachkontrolleIndikatoren = this.$store.state.api.selectedNKIndikatoren

      return gruppe.indikatoren.filter((indikator) => {
        if (this.isNachkontrolle(this.kontrolle)) {
          const indikatorInSelectedNachkontrolleIndikatoren = selectedNachkontrolleIndikatoren && selectedNachkontrolleIndikatoren.includes(indikator.id)
          return (this.kontrolle && controlIsFlexible && selectedNachkontrolleIndikatoren) ? indikatorInSelectedNachkontrolleIndikatoren : true
        } else {
          const indikatorInSelectedIndikatoren = selectedIndikatoren && selectedIndikatoren.includes(indikator.id)
          return (this.kontrolle && controlIsFlexible && selectedIndikatoren) ? indikatorInSelectedIndikatoren : true
        }
      })
    },
    /**
     * Get "Einzelkuh Indikator" state
     * @desc get sum "Fragen" & sum "Antworten"
     * @return {{fragen: Array, sumFragen: number, antworten: Array, sumAntworten: number}}
     */
    getEinzelkuhIndikatorState (tier = null) {
      const indikatoren = this.getIndikatorenByGroupId(INDIKATOR_GRUPPE_UUID_EINZELKUH)
      const fragen = indikatoren
        .map(indikator => indikator.fragen.map(child => ({ ...child })))
        .flat()

      const t = tier || this.$store.state.api.tier
      if (!t) {
        return false
      }

      const antworten = this.antworten.filter(antwort => antwort.tier_id === t.id)

      return {
        fragen: fragen,
        sumFragen: fragen.length,
        antworten: antworten,
        sumAntworten: antworten.length
      }
    },
    /**
     * Is "Einzelkuh" control finished
     * @return {boolean}
     */
    isEinzelkuhControlFinished () {
      const state = this.getEinzelkuhIndikatorState()
      return (state.sumAntworten === state.sumFragen && this.sumAdded === this.sumTotal)
    },
    /**
     * Get "Herde" name by control id
     * @param control
     * @return {Item<InstanceOf<Herde>>|null}
     */
    getHerdeName (control) {
      return this.herdeExists(control)
        ? Herde.find(control.herde_id).name
        : null
    },
    /**
     * Check if "Herde" exists
     * @param control
     * @return {boolean}
     */
    herdeExists (control) {
      return Herde.query()
        .where('id', control.herde_id)
        .exists()
    },
    /**
     * Get formatted date time string
     * @desc
     * - Get locale from "navigator.language" if available otherwise set fallback "de-DE"
     * @param date
     * @returns {string}
     */
    getLocalDateTime (date) {
      const d = new Date(mysql2JsDate(date))
      const locale = (navigator.language && navigator.language === 'de-DE') ? navigator.language : 'de-DE'
      return d.toLocaleString(locale, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      })
    },
    /**
     * Get formatted date string
     * @desc
     * - Get locale from "navigator.language" if available otherwise set fallback "de-DE"
     * @param date
     * @returns {string}
     */
    getLocalDate (date) {
      const d = new Date(mysql2JsDate(date))
      const locale = (navigator.language && navigator.language === 'de-DE') ? navigator.language : 'de-DE'
      return d.toLocaleString(locale, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      })
    },
    getFormattedDate (inputDate) {
      var date = new Date(inputDate)
      if (!isNaN(date.getTime())) {
        const d = date.getDate()
        let m = date.getMonth() + 1 // Months use 0 index.
        m = (m <= 9 ? '0' + m : m)
        const y = date.getFullYear()
        return d + '-' + m + '-' + y
      }
    },
    /**
     * Is the control disabled
     * @param control
     * @return {boolean}
     */
    isControlDisabled (control) {
      const createdAt = this.getLocalDateTime(control.benutzer_erstellt_am)
      const days = this.getDaysUntilDeletion(createdAt)
      return days === 0
    },
    /**
     * Get days until deletion
     * @param dateTime
     * @return {number}
     */
    getDaysUntilDeletion (dateTime) {
      const date = dateTime.split(', ')[0].split('.')
      const createdAt = new Date(date[2], date[1] - 1, date[0])

      const now = new Date() // '10/7/2020'

      const differenceInTime = now.getTime() - createdAt.getTime()
      const differenceInDays = differenceInTime / (1000 * 3600 * 24)

      let daysUntil = 0
      const daysUntilDeletion = 5

      daysUntil = daysUntilDeletion - parseInt(differenceInDays)
      return (daysUntil < 0) ? 0 : daysUntilDeletion - parseInt(differenceInDays)
    },
    /**
     * Detect android device
     * @return {boolean}
     */
    isAndroidDevice () {
      return 'device' in window && device.platform === 'Android'
    },
    /**
     * Detect is on device
     * @return {boolean}
     */
    isOnDevice () {
      return 'device' in window && device.platform !== 'browser' && this.isAppMode
    },
    /**
     * Fetch base data via vuex api actions
     * @returns {Promise<void>}
     */
    async fetchBaseData () {
      console.log('** FETCH_BASE_DATA_START')
      try {
        this.$store.commit('api/SET_SYNC_PROGRESS_STEPS_TOTAL', this.synchronisableEndpoints)
        for (const key in this.synchronisableEndpoints) {
          if (this.hasRequestError) {
            break
          }

          if (this.synchronisableEndpoints.hasOwnProperty(key)) {
            const endpoint = this.synchronisableEndpoints[key]
            await this.$store.dispatch('api/fetchEndpoint', { endpoint: endpoint, refresh: true })
          }
        }
      } catch (error) {
        this.$store.commit('api/REQUEST_ERROR', error)
      } finally {
        console.log('** FETCH_BASE_DATA_END')
        this.$store.commit('api/SET_SYNC_PROGRESS_STEPS_TOTAL', [])
      }
    },
    /**
     * Fetch user data via vuex api actions
     * @returns {Promise<void>}
     */
    async fetchUserData () {
      console.log('** FETCH_USER_DATA_START')
      try {
        await this.$store.dispatch('api/fetchUserData')
      } catch (error) {
        this.$store.commit('api/REQUEST_ERROR', error)
      } finally {
        console.log('** FETCH_USER_DATA_END')
      }
    },
    /**
     * Fetch media files
     * @return {Promise<void>}
     */
    async fetchMediaFiles () {
      console.log('** FETCH_MEDIA_FILES_START')

      const infos = Info.all()
      const list = getMediaFileList(infos, this.$store.state.api)
      this.$store.commit('api/SET_MEDIA_FILE_LIST', list)
      // console.log('MEDIA_FILE_LIST => ', this.mediaFileList)

      // Note: Only for development purpose
      /* const urls = [
        list.find(i => i.name === 'liegenkorrektlaz.jpg'),
        list.find(i => i.name === 'icon-hauptkontrolle-liege-stehverhalten-entfernung.svg'),
        list.find(i => i.name === 'icon-hauptkontrolle-liege-stehverhalten-beurteilung.svg')
      ] */

      await createMediaFileDirectory(this.mediaFileDirectoryName)

      this.$store.commit('api/MEDIA_FILES_REQUEST')
      this.$store.commit('api/SET_SYNC_PROGRESS_STEPS_TOTAL', list)

      for (const item of list) {
        try {
          // console.log('* FETCH_MEDIA_FILE URL', item.url)

          const blob = await fetchMediaFile(item.url)
          // console.log('* FETCH_MEDIA_FILE BLOB', blob)

          const response = await writeMediaFile(item.name, this.mediaFileDirectoryName, blob)
          // console.log('* WRITE_FILE RESPONSE', response)

          this.$store.commit('api/MEDIA_FILE_SUCCESS', {
            name: item.name,
            size: blob.size,
            type: blob.type,
            url: item.url,
            localURL: response.target.localURL
          })
        } catch (error) {
          console.error('* MEDIA_FILE ERROR', error)
          this.$store.commit('api/MEDIA_FILES_ERROR')
        }
      }

      console.log('** FETCH_MEDIA_FILES_END')
      this.$store.commit('api/MEDIA_FILES_SUCCESS')
      this.$store.commit('api/SET_SYNC_PROGRESS_STEPS_TOTAL', [])
    },
    /**
     * Show confirm modal
     * @param title
     * @param text
     * @param okTitle
     * @param cancelTitle
     * @param size
     * @param buttonSize
     * @param okVariant
     * @param cancelVariant
     * @param headerClass
     * @param bodyClass
     * @param footerClass
     * @param hideHeaderClose
     * @return {Promise<BvMsgBoxData>}
     */
    showConfirmModal (title, text, okTitle, cancelTitle, size = 'sm', buttonSize = 'sm', okVariant = 'danger', cancelVariant = 'secondary', headerClass = 'p-2', bodyClass = 'p-2', footerClass = 'p-2', hideHeaderClose = true) {
      return this.$bvModal.msgBoxConfirm(text, {
        title: title,
        size: size,
        buttonSize: buttonSize,
        okVariant: okVariant,
        okTitle: okTitle,
        cancelVariant: cancelVariant,
        cancelTitle: cancelTitle,
        headerClass: headerClass,
        bodyClass: bodyClass,
        footerClass: footerClass,
        hideHeaderClose: hideHeaderClose,
        centered: true,
        noFade: true,
        noCloseOnBackdrop: true,
        noCloseOnEsc: true
      })
    },
    /**
     * Show alert modal
     * @param title
     * @param text
     * @param okTitle
     * @param size
     * @param buttonSize
     * @param okVariant
     * @param headerClass
     * @param bodyClass
     * @param footerClass
     */
    showAlertModal (title, text, okTitle, size = 'sm', buttonSize = 'sm', okVariant = 'danger', headerClass = 'p-2', bodyClass = 'p-2', footerClass = 'p-2') {
      this.$bvModal.msgBoxOk(text, {
        title: title,
        size: size,
        buttonSize: buttonSize,
        okVariant: okVariant,
        okTitle: okTitle,
        headerClass: headerClass,
        bodyClass: bodyClass,
        footerClass: footerClass,
        centered: true,
        noFade: true,
        noCloseOnBackdrop: true,
        noCloseOnEsc: true
      })
        .then((res) => {
          this.$eventHub.$emit('DATA_PROCESS_EVENT_CANCELED')
        })
    },
    /**
     * Get title
     * @param jsonStr
     * @return {string}
     */
    getInfoTitle (jsonStr) {
      const json = JSON.parse(jsonStr)
      return (json && json.title) ? json.title : null
    },
    /**
     * Open info modal
     * @desc param info could be type of string (uuid) or object (Info model)
     * @param info
     * @param props
     * @return {boolean}
     */
    openInfoModal (info, props = {}) {
      if (typeof info !== 'string' &&
          typeof info !== 'object') {
        return false
      }

      const model = (typeof info === 'string') ? Info.find(info) : info
      if (!model) {
        return false
      }

      const h = this.$createElement
      const titleVNode = h('div', {
        domProps: {
          innerHTML: this.getInfoTitle(model.json),
          id: (typeof info === 'string') ? 'uuid-' + info : 'uuid-' + info.id
        }
      })

      const infoVNodes = this.getInfoDescriptions(model.json, props)

      this.$bvModal.msgBoxOk(infoVNodes, {
        title: [titleVNode],
        okVariant: 'link',
        okTitle: 'Schließen',
        modalClass: 'info-modal',
        centered: true,
        noFade: true,
        size: 'lg',
        headerBgVariant: 'white',
        scrollable: true
      })
    },
    /**
     * Get info descriptions as VNode
     * @param jsonStr
     * @param props
     * @return {VNode}
     */
    getInfoDescriptions (jsonStr, props) {
      const descriptions = this.parseAndMapDescriptions(jsonStr)

      const h = this.$createElement
      const containerNode = h('b-container', { class: ['info-container'], attrs: { fluid: true } }, [])

      descriptions.forEach(desc => {
        const imageColNodes = []
        const hasImages = (Array.isArray(desc.images) && desc.images.length)
        if (hasImages) {
          desc.images.forEach(image => {
            if (image.file) {
              const imageNode = h('b-img', {
                class: ['mb-2'],
                attrs: {
                  src: image.file,
                  center: true,
                  fluid: true
                }
              })
              const imageTextNode = (image.text) ? h('p', {
                class: ['image-copyright '],
                domProps: {
                  innerHTML: image.text
                }
              }) : null

              const col = h('b-col', { class: [] }, [imageNode, imageTextNode])
              imageColNodes.push(col)
            }
          })
          const imageRowNode = h('b-row', { class: [] }, imageColNodes)
          containerNode.children.push(imageRowNode)
        }

        // TODO: check if needed...
        const valueColNode = (props.value && props.state !== 'light') ? h('b-col', {
          class: ['info-value col-12 mb-3'],
          domProps: {
            innerHTML: `${this.messages.results.value} ${props.value}`
          }
        }) : null

        const textColNode = h('b-col', {
          class: ['info-text col-12'],
          domProps: {
            innerHTML: desc.text.replace('{value}', props.value)
          }
        })

        const textRowNode = h('b-row', { class: [] }, [valueColNode, textColNode])
        containerNode.children.push(textRowNode)

        if (hasImages) {
          const hr = h('hr', {
            class: ['mb-5']
          })

          containerNode.children.push(hr)
        }
      })

      return containerNode
    },
    /**
     * Parse json string & map item properties
     * @param jsonStr
     * @return {Array}
     */
    parseAndMapDescriptions (jsonStr) {
      const json = JSON.parse(jsonStr)
      return (json && json.descriptions) ? json.descriptions.map(description => {
        return {
          title: (json.title) ? json.title : '',
          text: (description && description.markdown) ? marked(description.markdown) : '',
          images: (description && description.images) ? description.images.map(image => {
            return {
              text: image.markdown,
              file: this.getAbsoluteImagePath(image.file)
            }
          }) : null
        }
      }) : []
    },
    /**
     * Print view
     */
    print () {
      window.print()
    },
    /**
     * On Device get absolute image path from local file system, in browser version get remote image url
     * @desc "WkWebView.convertFilePath" is not available in android platform
     * @see cordova/node_modules/cordova-ios/cordova-js-src/plugin/ios/wkwebkit.js
     * @see cordova/node_modules/cordova-ios/CordovaLib/cordova.js
     * @return {string}
     */
    getAbsoluteImagePath (path) {
      const remoteUrl = `${this.$store.state.api.apiBaseUrl}${this.$store.state.api.fileflyApiEndpoint}${path}`
      if ('device' in window && device.platform !== 'browser') {
        const name = path.split('/').pop()
        const localUrl = this.mediaFileDirectoryUrl + name
        switch (device.platform) {
          case 'iOS':
            return window.WkWebView.convertFilePath(localUrl)
          case 'Android':
            return `${window.location.origin}/__cdvfile_files__/${this.$store.state.api.mediaFileDirectoryName}/${name}`
          default:
            return remoteUrl
        }
      } else {
        return remoteUrl
      }
    },
    /**
     * Check if url starts with "http(s)" & internet connection is available
     * @returns {boolean}
     */
    requireConnection (url) {
      const regExpHttp = /http[s]?/g
      if (this.$store.state.connectionState === 'error' && regExpHttp.test(url)) {
        const title = 'Internet Verbindung erforderlich!'
        const text = 'Einige Inhalte dieser App erfordern eine Internetverbindung.'
        this.showAlertModal(title, text, 'ok')
        return true
      }
      return false
    },
    /**
     * Open "cordova-plugin-inappbrowser" or system default browser
     * @param url
     * @param target
     * @returns {boolean}
     */
    openInAppBrowser (url, target) {
      if (!url) {
        this.showAlertModal('Error', 'URL is missing!', 'Ok')
        return false
      }

      if (this.requireConnection(url)) {
        return false
      }

      const _target = target || '_blank'
      const options = 'location=no,' +
        'usewkwebview=yes,' +
        'enableviewportscale=yes,' +
        'closebuttoncolor=#FFFFFF,' +
        'toolbarposition=top,' +
        'toolbarcolor=#000000,' +
        'closebuttoncaption=Schließen,' +
        'disallowoverscroll=yes,' + // yes or no (default)
        'hidenavigationbuttons=yes,' +
        // 'toolbar=yes,' + // no or yes (default)
        // 'mediaPlaybackRequiresUserAction=no,' +
        // 'allowInlineMediaPlayback=yes,' +
        'transitionstyle=coververtical,' + // fliphorizontal, crossdissolve or coververtical (default)
        'presentationstyle=fullscreen' // pagesheet, formsheet or fullscreen (default)

      if (window.cordova && window.cordova.InAppBrowser) {
        cordova.InAppBrowser.open(url, _target, options)
      } else {
        window.open(url, _target)
      }
    },
    /**
     * Open native alert dialog via "cordova-plugin-dialogs"
     * @param title
     * @param message
     * @param btnText
     * @param cb
     */
    notifyAlert (title, message, btnText, cb) {
      if (navigator.notification) {
        navigator.notification.alert(
          (message && message.message) ? message.message : message,
          cb,
          title,
          btnText
        )
      } else {
        window.alert(message)
        if (cb) {
          cb()
        }
      }
    },
    /**
     * Open native confirm dialog via "cordova-plugin-dialogs"
     * @param title
     * @param message
     * @param buttonLabels
     * @param cb
     */
    notifyConfirm (title, message, buttonLabels = ['OK', 'Cancel'], cb) {
      if (navigator.notification) {
        navigator.notification.confirm(
          message,
          cb,
          title,
          buttonLabels
        )
      } else {
        const response = (window.confirm(message) === true) ? 1 : 2
        if (cb) {
          cb(response)
        }
      }
    },
    /**
     * Send data ("Herde", "Kontrolle" & "Antworten") via vuex api module actions
     * @param data
     * @param statusSync
     * @return {Promise<void>}
     */
    async sendDataToServer (data, statusSync) {
      console.log('SEND_DATA_TO_SERVER', data, statusSync)
      try {
        const herde = Herde.find(data.kontrolle.herde_id)

        if (herde && herde.herde_vorlage_id) {
          await this.$store.dispatch('api/pushHerdeVorlage', herde.herde_vorlage_id)
        }

        if (data.kontrolle.hauptkontrolle_id !== null) {
          /* const hauptkontrolle = Kontrolle.find(data.kontrolle.hauptkontrolle_id)
          if (hauptkontrolle && !hauptkontrolle.benutzer_synchronisiert_am) {
            await this.$store.dispatch('api/pushHerde', data.kontrolle.herde_id)
            await this.$store.dispatch('api/pushControl', { id: data.kontrolle.hauptkontrolle_id, statusSync })
          } */

          await this.$store.dispatch('api/pushControl', { id: data.kontrolle.id, statusSync })
        } else {
          if (statusSync === STATUS_SYNC_INITIAL || statusSync === STATUS_SYNC_KONTROLLE_FINISHED) {
            await this.$store.dispatch('api/pushHerde', data.kontrolle.herde_id)
          }

          await this.$store.dispatch('api/pushControl', { id: data.kontrolle.id, statusSync })

          const nachkontrolle = Kontrolle.query().where('hauptkontrolle_id', data.kontrolle.id).first()
          if (nachkontrolle && !nachkontrolle.benutzer_synchronisiert_am && nachkontrolle.status === CONTROL_STATE_FINISHED) {
            this.$store.dispatch('api/pushControl', { id: nachkontrolle.id, statusSync })
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}

export default mixins
