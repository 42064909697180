import Indikator from '@/store/modules/api/models/Indikator'
import IndikatorGruppe from '@/store/modules/api/models/IndikatorGruppe'
import Frage from '@/store/modules/api/models/Frage'
import Tier from '@/store/modules/api/models/Tier'
import Herde from '@/store/modules/api/models/Herde'
import Massnahme from '@/store/modules/api/models/Massnahme'
import Kontrolle from '@/store/modules/api/models/Kontrolle'
import Antwort from '@/store/modules/api/models/Antwort'
// import Datei from '@/store/modules/api/models/Datei'
import {
  CONTROL_TYPE_TEATS,
  FRAGE_TYP_UUID_TEST,
  FRAGE_UUID_BELEGUNG_LAKTIERENDE_TIERE,
  FRAGE_UUID_BELEGUNG_LEISTUNGSGRUPPE,
  FRAGE_UUID_WASSERVERSORGUNG, FRAGE_UUID_WASSERVERSORGUNG_LEISTUNGSGRUPPE,
  INDIKATOR_GRUPPE_UUID_EINZELKUH,
  INDIKATOR_GRUPPE_UUID_HERDE,
  INDIKATOR_GRUPPE_UUID_HERDE2,
  INDIKATOR_GRUPPE_UUID_PRODUKTIONSSYSTEM,
  INDIKATOR_GRUPPE_UUID_URSACHENANALYSE,
  INDIKATOR_GRUPPE_UUID_ZITZENKONTROLLE,
  INDIKATOR_UUID_ERGAENZENDE_INDIKATOREN,
  INDIKATOR_UUID_HORNSTOSSVERLETZUNGEN, INDIKATOR_UUID_LAHMHEITEN, INDIKATOR_UUID_LIEGE_STEHVERHALTEN,
  INDIKATOR_UUID_WASSERVERSORGUNG,
  INDIKATOR_UUID_ZITZENKONDITION,
  parseMarkdownProperties,
  STATUS_SYNC_DELETED
} from '@/store/utils'

/**
 * Returns the number of seconds elapsed since the Unix Epoch
 * @returns {number}
 */
const now = () => {
  return Math.floor(Date.now() / 1000)
}

/**
 * Create absolute api endpoint url
 * @param state
 * @param endpoint
 * @return {string}
 */
const createApiEndpointUrl = (state, endpoint) => {
  return `${state.apiBaseUrl}${state.endpoints[endpoint]}`
}

const getters = {
  /**
   * API url getters
   * @param state
   * @returns {string}
   */
  loginUrl: state => createApiEndpointUrl(state, 'login'),
  registerUrl: state => createApiEndpointUrl(state, 'register'),
  refreshUrl: state => createApiEndpointUrl(state, 'refresh'),
  profileUrl: state => createApiEndpointUrl(state, 'profile'),
  changedUrl: state => createApiEndpointUrl(state, 'changed'),
  antwortUrl: state => createApiEndpointUrl(state, 'antwort'),
  ergebnisUrl: state => createApiEndpointUrl(state, 'ergebnis'),
  frageUrl: state => createApiEndpointUrl(state, 'frage'),
  fragenTypUrl: state => createApiEndpointUrl(state, 'fragenTyp'),
  frageXInfoUrl: state => createApiEndpointUrl(state, 'frageXInfo'),
  herdeUrl: state => createApiEndpointUrl(state, 'herde'),
  herdeVorlageUrl: state => createApiEndpointUrl(state, 'herdeVorlage'),
  indikatorUrl: state => createApiEndpointUrl(state, 'indikator'),
  indikatorGruppeUrl: state => createApiEndpointUrl(state, 'indikatorGruppe'),
  indikatorGruppeXInfoUrl: state => createApiEndpointUrl(state, 'indikatorGruppeXInfo'),
  indikatorXInfoUrl: state => createApiEndpointUrl(state, 'indikatorXInfo'),
  infoUrl: state => createApiEndpointUrl(state, 'info'),
  kontrolleUrl: state => createApiEndpointUrl(state, 'kontrolle'),
  dateiUrl: state => createApiEndpointUrl(state, 'datei'),
  massnahmeUrl: state => createApiEndpointUrl(state, 'massnahme'),
  ergebnisXInfoUrl: state => createApiEndpointUrl(state, 'ergebnisXInfo'),
  ursachenanalyseUrl: state => createApiEndpointUrl(state, 'ursachenanalyse'),

  /**
   * Synchronizable endpoints
   * @desc all endpoints for the initial data synchronization, exclude endpoints in "ignores" array
   * @param state
   * @return {[]}
   */
  synchronisableEndpoints: (state) => {
    const ignores = ['login', 'register', 'profile', 'refresh', 'changed', 'herde', 'herdeVorlage', 'kontrolle', 'antwort']
    return Object.keys(state.endpoints).filter(endpoint => !ignores.includes(endpoint))
  },
  /**
   * Get claims from JWT
   * @param state
   * @returns {string|null}
   */
  jwtClaims: state => (state.jwt) ? JSON.parse(atob(state.jwt.split('.')[1])) : null,
  /**
   * Is JWT expired
   * @desc Check if "exp" claim is expired
   * @param state
   * @param getters
   * @returns {boolean}
   */
  isJwtExpired: (state, getters) => () => {
    return !!(getters.jwtClaims && getters.jwtClaims.exp < now())
  },
  /**
   * The JWT is refreshable when:
   * Has claims, "stayLoggedIn" & "refreshAllowed" claim is true and "now" >= "refreshAfter" claim
   * Test: 86400 => 1 day, 1296000 => 15 days, 2592000 => 30 days
   * (now() + 1296000 (15 days)) >= getters.jwtClaims.refreshAfter => can refresh
   * (now() + 1209600 (14 days)) >= getters.jwtClaims.refreshAfter => can't refresh
   * @param state
   * @param getters
   * @returns {boolean}
   */
  isJwtRefreshable: (state, getters) => () => {
    return !!(getters.jwtClaims &&
           getters.jwtClaims.refreshAllowed &&
           state.stayLoggedIn &&
           now() >= getters.jwtClaims.refreshAfter)
  },
  /**
   * Is user authenticated
   * @desc Is JWT set & JWT is not expired
   * @param state
   * @param getters
   * @returns {boolean}
   */
  isAuthenticated: (state, getters) => {
    return !!(state.jwt) && state.isLoggedIn
  },
  /**
   * Has all initial data
   * @param state
   * @returns {boolean}
   */
  hasInitialData: (state) => {
    return !!Indikator.all().length &&
           // !!Datei.all().length &&
           !!Frage.all().length
  },
  /**
   * Detect loading states
   * @param state
   * @returns {boolean}
   */
  isLoading: (state) => {
    return state.authStatus === 'loading' ||
           state.requestStatus === 'loading' ||
           state.mediaFileStatus === 'loading'
  },
  /**
   * Detect error states
   * @param state
   * @returns {boolean}
   */
  hasAuthError: (state) => {
    return state.authStatus === 'error'
  },
  hasRequestError: (state) => {
    return state.requestStatus === 'error'
  },
  /**
   * Returns the "Tier" component name
   * @param state
   * @return {string}
   */
  tierComponentName: (state) => {
    return 'Tier'
  },
  /**
   * Get current selected "Herde" in kontrolle
   * @param state
   * @param getters
   * @return {Item<InstanceOf<Herde>>|null}
   */
  herde: (state, getters) => {
    if (!getters.kontrolle) {
      return null
    }

    return Herde.find(getters.kontrolle.herde_id)
  },
  /**
   * Is public route
   * @param state
   * @param getters
   * @return {boolean}
   */
  isPublicRoute: (state, getters) => (name) => {
    const routes = ['produktionssystem', 'ursachenanalyse', 'massnahmenvorschlaege']
    return (!getters.isAuthenticated && !routes.includes(name)) || getters.isAuthenticated
  },
  /**
   * Get "Massnahme" by "frage_id", "begruendung" & "text" is markdown formatted
   * @return {Item<InstanceOf<Massnahme>>|null}
   */
  getMassnahmeByFrageId: () => (id) => {
    const massnahme = Massnahme.query()
      .where('frage_id', id)
      .first()

    if (!massnahme) {
      return null
    }

    parseMarkdownProperties(massnahme)
    return massnahme
  },
  /**
   * Returns an array of "Kuerzel" extracted from Ursachenanalyse -> indikatoren -> fragen -> antworten
   * with answer === "inkorrekt", filtered by kontrolle id and excluding the fragen type "test"
   * @param state
   * @param getters
   * @return {*}
   */
  getUrsachenanalyseAntworten: (state, getters) => {
    const gruppe = IndikatorGruppe.query()
      .whereId(INDIKATOR_GRUPPE_UUID_URSACHENANALYSE)
      .with('indikatoren.fragen.antworten.frage.indikator')
      .first()

    const fragen = gruppe.indikatoren.map((indikator) => indikator.fragen).flat()
    const antworten = fragen.map((frage) => frage.antworten).flat()
    const filterByKontrolle = antworten.filter((antwort) => antwort.kontrolle_id === getters.kontrolle.id)
    const filterByFrageTyp = filterByKontrolle.filter((antwort) => antwort.frage.frage_typ_id !== FRAGE_TYP_UUID_TEST)

    return filterByFrageTyp.filter((antwort) => JSON.parse(antwort.json) === 'inkorrekt')
  },
  /**
   * Get media file directory
   * @desc
   * - Android => /data/user/0/de.hrzg.lazbw.proqbw/files/media-files/
   * - iOS => /var/mobile/Containers/Data/Application/00A22790-A45C-4904-AFFD-9610C65AD65C/Library/NoCloud/media-files/
   * @param state
   * @return {string|string}
   */
  mediaFileDirectoryUrl: (state) => {
    /* global cordova */
    return (cordova && cordova.file) ? `${cordova.file.dataDirectory}${state.mediaFileDirectoryName}/` : ''
  },
  /**
   * Get all "Kontrollen"
   * @param state
   * @return {Collection<InstanceOf<Kontrolle>>}
   */
  kontrollen: (state) => {
    return Kontrolle.query()
      .where('typ', (value) => value !== CONTROL_TYPE_TEATS)
      .where('hauptkontrolle_id', (value) => value === null)
      .where('status_sync', (value) => value !== STATUS_SYNC_DELETED)
      .orderBy('benutzer_erstellt_am', 'desc')
      // .withAllRecursive()
      .all()
  },
  /**
   * Get current "Kontrolle"
   * @param state
   * @return {Item<InstanceOf<Kontrolle>>}
   */
  kontrolle: (state) => {
    return Kontrolle.query()
      .whereId(state.kontrolleId)
      // .with(['antworten.frage.indikator|infos', 'tiere'])
      .first()
  },
  /**
   * Get all "antworten" by "kontrolle_id"
   * @param state
   * @return {Collection<InstanceOf<Antwort>>}
   */
  antworten: (state) => {
    return Antwort.query()
      .where('kontrolle_id', state.kontrolleId)
      .with(['frage.indikator|infos'])
      .get()
  },
  /**
   * Get all "tiere" by "kontrolle_id"
   * @param state
   * @return {Collection<InstanceOf<Tier>>}
   */
  tiere: (state) => {
    return Tier.query()
      .where('kontrolle_id', state.kontrolleId)
      .get()
  },
  /**
   * Get Produktionssystem Fragen
   * @param state
   * @param getters
   * @return {*[]|*}
   */
  produktionssystemFragen: (state, getters) => {
    const haltungssystem = (getters.herde && getters.herde.haltungssystem)
      ? getters.herde.haltungssystem
      : null

    const gruppe = IndikatorGruppe.query()
      .whereId(INDIKATOR_GRUPPE_UUID_PRODUKTIONSSYSTEM)
      .with('indikatoren', query => {
        query.with('fragen', query => {
          query.where(frage => {
            // TODO: Type check...
            if (frage.meta_data && frage.meta_data !== 'null') {
              const metaData = JSON.parse(frage.meta_data)
              return (metaData.hasOwnProperty('her')) ? metaData.her.includes(haltungssystem) : true
            }
            return true
          })
        })
          .orderBy('rang')
      })
      .first()

    if (!gruppe) {
      return []
    }

    const indikatoren = gruppe.indikatoren
    return indikatoren
      .map((indikator) => indikator.fragen)
      .flat()
  },
  /**
   * Get Produktionssystem Fragen by haltungssystem
   * @param state
   * @param getters
   * @return {*[]|*}
   */
  getProduktionssystemFragenByHaltungssystem: (state, getters) => (haltungssystem = null) => {
    const gruppe = IndikatorGruppe.query()
      .whereId(INDIKATOR_GRUPPE_UUID_PRODUKTIONSSYSTEM)
      .with('indikatoren', query => {
        query.with('fragen', query => {
          query.where(frage => {
            if (frage.meta_data && frage.meta_data !== 'null') {
              const metaData = JSON.parse(frage.meta_data)
              return (metaData.hasOwnProperty('her')) ? metaData.her.includes(haltungssystem) : true
            }
            return true
          })
        })
          .orderBy('rang')
      })
      .first()

    if (!gruppe) {
      return []
    }

    const indikatoren = gruppe.indikatoren
    return indikatoren
      .map((indikator) => indikator.fragen)
      .flat()
  },
  /**
   * Get all "IndikatorGruppen" with "Indikatoren" & "Fragen"
   * @param state
   * @param getters
   * @return {Collection<InstanceOf<IndikatorGruppe>>|*[]}
   */
  indikatorGruppen: (state, getters) => {
    if (!getters.kontrolle) {
      return []
    }

    const haltungssystem = (getters.herde && getters.herde.haltungssystem)
      ? getters.herde.haltungssystem
      : null

    const groups = IndikatorGruppe.query()
      .where((group) =>
        group.id === INDIKATOR_GRUPPE_UUID_HERDE ||
        group.id === INDIKATOR_GRUPPE_UUID_EINZELKUH ||
        group.id === INDIKATOR_GRUPPE_UUID_HERDE2 ||
        group.id === INDIKATOR_GRUPPE_UUID_ZITZENKONTROLLE)
      .with('indikatoren', (query) => {
        query
          .where((indikator) => {
            switch (indikator.id) {
              case INDIKATOR_UUID_ZITZENKONDITION:
                return (getters.kontrolle && getters.kontrolle.typ === CONTROL_TYPE_TEATS)
              case INDIKATOR_UUID_HORNSTOSSVERLETZUNGEN:
                return (getters.herde && getters.herde.horntragend) || false
              default:
                return (getters.kontrolle && getters.kontrolle.typ !== CONTROL_TYPE_TEATS)
            }
          })
          .orderBy('rang', 'asc')
          .with('fragen', (query) => {
            query.with('infos')
            query.where(frage => {
              switch (frage.indikator_id) {
                case INDIKATOR_UUID_ERGAENZENDE_INDIKATOREN:
                case INDIKATOR_UUID_WASSERVERSORGUNG:
                  const leistungsgruppe = (getters.herde && getters.herde.leistungsgruppe) || 0
                  switch (leistungsgruppe) {
                    case 1:
                      return (frage.id !== FRAGE_UUID_BELEGUNG_LAKTIERENDE_TIERE && frage.id !== FRAGE_UUID_WASSERVERSORGUNG)
                    case 0:
                      return (frage.id !== FRAGE_UUID_BELEGUNG_LEISTUNGSGRUPPE && frage.id !== FRAGE_UUID_WASSERVERSORGUNG_LEISTUNGSGRUPPE)
                    default:
                      return true
                  }
                case INDIKATOR_UUID_LIEGE_STEHVERHALTEN:
                case INDIKATOR_UUID_LAHMHEITEN:
                  // TODO: Type check...
                  if (frage.meta_data && frage.meta_data !== 'null') {
                    const metaData = JSON.parse(frage.meta_data)
                    return (metaData.hasOwnProperty('her')) ? metaData.her.includes(haltungssystem) : true
                  }
                  return true
                default:
                  return true
              }
            })
          })
          .orderBy('rang')
          .with('infos')
      })
      .get()

    groups.splice(2, 0, groups.pop())
    return groups
  },
  /**
   * Get absolute privacy policy url
   * @param state
   * @return {string}
   */
  privacyPolicyUrl: (state) => {
    return `${state.apiBaseUrl}${state.privacyPolicyUrl}`
  },
  /**
   * Get absolute user forgot password url
   * @param state
   * @return {string}
   */
  userForgotUrl: (state) => {
    return `${state.apiBaseUrl}${state.userForgotUrl}`
  },
  /**
   * Get absolute user resend password url
   * @param state
   * @return {string}
   */
  userResendUrl: (state) => {
    return `${state.apiBaseUrl}${state.userResendUrl}`
  },
  /**
   * Get absolute user profile url
   * @param state
   * @return {string}
   */
  userProfileUrl: (state) => {
    return `${state.apiBaseUrl}${state.userProfileUrl}`
  }
}

export default getters
