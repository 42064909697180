import { Database } from '@vuex-orm/core'

import Kontrolle from '@/store/modules/api/models/Kontrolle'
import Herde from '@/store/modules/api/models/Herde'
import HerdeVorlage from '@/store/modules/api/models/HerdeVorlage'
import HerdePreset from '@/store/modules/api/models/HerdePreset'
import IndikatorGruppe from '@/store/modules/api/models/IndikatorGruppe'
import Indikator from '@/store/modules/api/models/Indikator'
import Frage from '@/store/modules/api/models/Frage'
import FrageXInfo from '@/store/modules/api/models/FrageXInfo'
import Antwort from '@/store/modules/api/models/Antwort'
import Ergebnis from '@/store/modules/api/models/Ergebnis'
import Tier from '@/store/modules/api/models/Tier'
import Info from '@/store/modules/api/models/Info'
import IndikatorXInfo from '@/store/modules/api/models/IndikatorXInfo'
import Datei from '@/store/modules/api/models/Datei'
import Massnahme from '@/store/modules/api/models/Massnahme'
import ErgebnisXInfo from '@/store/modules/api/models/ErgebnisXInfo'
import Ursachenanalyse from '@/store/modules/api/models/Ursachenanalyse'

const database = new Database()

database.register(Kontrolle)
database.register(Herde)
database.register(HerdeVorlage)
database.register(HerdePreset)
database.register(IndikatorGruppe)
database.register(Indikator)
database.register(Frage)
database.register(FrageXInfo)
database.register(Antwort)
database.register(Ergebnis)
database.register(Tier)
database.register(Info)
database.register(IndikatorXInfo)
database.register(Datei)
database.register(Massnahme)
database.register(ErgebnisXInfo)
database.register(Ursachenanalyse)

export default database
