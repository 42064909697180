import mutations from './mutations'
import actions from './actions'
import getters from '@/store/modules/api/getters'
import { localStorage } from '../../utils'
import Configuration from '@/configuration'

export default {
  namespaced: true,
  state: {
    apiBaseUrl: Configuration.value('apiBaseUrl'),
    fileflyApiEndpoint: Configuration.value('fileflyApiEndpoint'),
    privacyPolicyUrl: Configuration.value('privacyPolicyUrl'),
    userForgotUrl: Configuration.value('userForgotUrl'),
    userResendUrl: Configuration.value('userResendUrl'),
    userProfileUrl: Configuration.value('userProfileUrl'),
    endpoints: {
      login: '/mobile/auth/login',
      register: '/mobile/auth/register',
      refresh: '/mobile/auth/refresh',
      profile: '/mobile/auth/profile',
      changed: '/mobile/api-meta/changed',
      antwort: '/mobile/antwort',
      ergebnis: '/mobile/ergebnis',
      frage: '/mobile/frage?sort=rang',
      fragenTyp: '/mobile/fragen-typ',
      frageXInfo: '/mobile/frage-x-info',
      herde: '/mobile/herde',
      herdeVorlage: '/mobile/herde-vorlage',
      indikator: '/mobile/indikator?sort=rang',
      indikatorGruppe: '/mobile/indikator-gruppe',
      indikatorGruppeXInfo: '/mobile/indikator-gruppe-x-info',
      indikatorXInfo: '/mobile/indikator-x-info',
      info: '/mobile/info',
      kontrolle: '/mobile/kontrolle',
      // datei: '/mobile/datei',
      massnahme: '/mobile/massnahme',
      ergebnisXInfo: '/mobile/ergebnis-x-info',
      ursachenanalyse: '/mobile/ursachenanalyse'
    },
    jwt: localStorage.fetch('jwt') || null,
    updatedAt: localStorage.fetch('updatedAt') || null,
    apiUpdatedAt: localStorage.fetch('apiUpdatedAt') || null,
    apiUpdated: false,
    authStatus: null,
    authError: null,
    requestStatus: null,
    requestError: null,
    syncProgressSteps: 0,
    syncProgressStepsTotal: 0,
    isLoggedIn: localStorage.fetch('isLoggedIn') || false,
    stayLoggedIn: localStorage.fetch('stayLoggedIn') || (localStorage.fetch('stayLoggedIn') !== false),
    user: localStorage.fetch('user') || {
      email: null,
      username: null
    },
    profile: localStorage.fetch('profile') || null,
    showInfoAgain: localStorage.fetch('showInfoAgain'),
    selectedIndikatoren: localStorage.fetch('selectedIndikatoren') || [],
    selectedNKIndikatoren: [],
    tier: null,
    mediaFilesUpdatedAt: localStorage.fetch('mediaFilesUpdatedAt') || null,
    mediaFileList: localStorage.fetch('mediaFileList') || null,
    mediaFileDirectoryName: 'media-files',
    mediaFileStatus: null,
    kontrolleId: null
  },
  mutations: mutations,
  actions: actions,
  getters: getters
}
