import { Model } from '@vuex-orm/core'
import uuid from 'uuid'
import Kontrolle from '@/store/modules/api/models/Kontrolle'

export default class Herde extends Model {
  static entity = 'herden'

  static fields () {
    return {
      id: this.uid(() => uuid()),
      herde_vorlage_id: this.string(null).nullable(),
      user_uuid: this.string(null).nullable(),
      name: this.string(''),
      haltungssystem: this.string(null).nullable(),
      leistungsgruppe: this.number(0),
      gesamt: this.number(null),
      laktierend: this.number(null),
      stichprobe: this.number(null),
      fokusgruppe: this.number(null),
      fokusgruppe_name: this.string(''),
      horntragend: this.number(0),
      benutzer_erstellt_am: this.string(null).nullable(),
      benutzer_bearbeitet_am: this.string(null).nullable(),
      benutzer_synchronisiert_am: this.string(null).nullable(),
      erstellt_am: this.string(null).nullable(),
      bearbeitet_am: this.string(null).nullable(),
      kontrollen: this.hasMany(Kontrolle, 'herde_id')
    }
  }
}
