import * as types from './mutation-types'
import { localStorage } from '@/store/utils'
import Kontrolle from '@/store/modules/api/models/Kontrolle'
import Herde from '@/store/modules/api/models/Herde'
import HerdeVorlage from '@/store/modules/api/models/HerdeVorlage'
import HerdePreset from '@/store/modules/api/models/HerdePreset'
import IndikatorGruppe from '@/store/modules/api/models/IndikatorGruppe'
import Indikator from '@/store/modules/api/models/Indikator'
import Frage from '@/store/modules/api/models/Frage'
import Antwort from '@/store/modules/api/models/Antwort'
import Ergebnis from '@/store/modules/api/models/Ergebnis'
import Tier from '@/store/modules/api/models/Tier'
import Info from '@/store/modules/api/models/Info'
import IndikatorXInfo from '@/store/modules/api/models/IndikatorXInfo'
import Datei from '@/store/modules/api/models/Datei'
import Massnahme from '@/store/modules/api/models/Massnahme'
import ErgebnisXInfo from '@/store/modules/api/models/ErgebnisXInfo'

/**
 * Get locale date string
 * @returns {string}
 */
const getLocaleDateString = () => {
  return new Date().toLocaleString(navigator.language, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  })
}

/**
 * Reset state & local storage
 * @param state
 */
const resetState = (state) => {
  resetErrorStates(state)

  localStorage.remove('isLoggedIn')
  state.isLoggedIn = false

  localStorage.remove('user')
  state.user = {
    email: null,
    username: null
  }

  localStorage.remove('profile')
  state.profile = null

  localStorage.remove('antwort')
  Antwort.deleteAll()

  localStorage.remove('kontrolle')
  Kontrolle.deleteAll()

  localStorage.remove('herde')
  Herde.deleteAll()

  localStorage.remove('herdePreset')
  HerdePreset.deleteAll()

  localStorage.remove('tier')
  Tier.deleteAll()
  state.tier = null

  localStorage.remove('jwt')
  state.jwt = null

  localStorage.remove('updatedAt')
  state.updatedAt = null

  localStorage.remove('apiUpdatedAt')
  state.apiUpdatedAt = null

  localStorage.remove('showInfoAgain')
  state.showInfoAgain = null
}

/**
 * Reset error states
 * @param state
 */
const resetErrorStates = (state) => {
  state.authStatus = null
  state.authError = null
  state.requestStatus = null
  state.requestError = null
}

/**
 * Get error messages as string
 * @param result
 * @return {string}
 */
const getErrors = (result) => {
  const res = result.map(item => item.message)
  return res.join('\n')
}

/**
 * Handle error response
 * @param response
 * @param errors
 * @return {Promise<string>}
 */
const handleErrorResponse = async (response, errors) => {
  console.log('HANDLE_ERROR_RESPONSE', response)
  let message
  switch (response.status) {
    case 401:
      message = errors.AUTHORIZATION_ERROR
      break
    case 403:
      message = errors.INVALID_CREDENTIAL_ERROR
      break
    case 422:
      const result = await response.json()
      message = Array.isArray(result) ? getErrors(result) : result.message
      break
    case 500:
      message = errors.INTERNAL_SERVER_ERROR
      break
    default:
      message = (typeof response.json === 'function') ? await response.json() : response
  }

  if (message.message === 'Failed to fetch') {
    message.name = 'Achtung'
    message.message = errors.FAILED_TO_FETCH_MESSAGE
  }

  return message
}

/**
 * Insert data via vuex-orm & save in localStorage
 * @param type
 * @param data
 */
const insertData = (type, data) => {
  console.log('INSERT_DATA', type)
  switch (type) {
    case 'indikatorGruppe':
      IndikatorGruppe.insert({ data: data })
      break
    case 'indikator':
      Indikator.insert({ data: data })
      break
    case 'frage':
      Frage.insert({ data: data })
      break
    case 'info':
      Info.insert({ data: data })
      break
    case 'indikatorXInfo':
      IndikatorXInfo.insert({ data: data })
      break
    case 'ergebnisXInfo':
      ErgebnisXInfo.insert({ data: data })
      break
    case 'ergebnis':
      Ergebnis.insert({ data: data })
      break
    case 'datei':
      // Datei.insert({ data: data })
      break
    case 'massnahme':
      Massnahme.insert({ data: data })
      break
    default:
      // console.log(type)
  }

  localStorage.save(type, data)
}

const mutations = {
  /**
   * Auth loading state
   * @param state
   */
  [types.AUTH_REQUEST] (state) {
    state.authStatus = 'loading'
  },
  /**
   * Auth success state
   * @param state
   * @param data
   */
  [types.AUTH_SUCCESS] (state, data) {
    state.authStatus = 'success'
  },
  /**
   * Auth error state
   * @param state
   * @param error
   */
  async [types.AUTH_ERROR] (state, error) {
    let message = await handleErrorResponse(error, this.getters['help/errors'])

    state.authStatus = 'error'
    state.authError = (message.name && message.message) ? `${message.name} - ${message.message}` : message

    setTimeout(() => {
      resetErrorStates(state)
    }, 10000)

    throw Error(state.authError)
  },
  /**
   * Request loading state
   * @param state
   */
  [types.REQUEST_LOADING] (state) {
    state.requestStatus = 'loading'
  },
  /**
   * Request success state
   * @param state
   * @param response
   */
  [types.REQUEST_SUCCESS] (state, response) {
    console.log('api/REQUEST_SUCCESS: ', response)
    if (response.success) {
      state.requestStatus = 'success'

      switch (response.type) {
        case 'fetch':
        case 'push':
          // ---
          break
        case 'changed':
          state.apiUpdated = (new Date(state.apiUpdatedAt * 1000) < new Date(response.data.updatedAt * 1000))
          if (state.apiUpdated) {
            localStorage.save('apiUpdatedAt', response.data.updatedAt)
            state.apiUpdatedAt = response.data.updatedAt
          }
          break
        default:
          // TODO: check refresh handling...
          // const data = (response.refresh) ? response.data : [...state[response.type], ...response.data]
          insertData(response.type, response.data)

          state.syncProgressSteps++

          const now = getLocaleDateString()
          localStorage.save('updatedAt', now)
          state.updatedAt = now
      }
    }
  },
  /**
   * Request error state
   * @param state
   * @param error
   */
  async [types.REQUEST_ERROR] (state, error) {
    let message = await handleErrorResponse(error, this.getters['help/errors'])

    state.requestStatus = 'error'
    state.requestError = (message.name && message.message) ? `${message.name} - ${message.message}` : message

    throw Error(state.requestError)
  },
  /**
   * Reset error states
   * @param state
   */
  [types.RESET_ERROR_STATES] (state) {
    resetErrorStates(state)
  },
  /**
   * Set sync progress steps total by arr length
   * @param state
   * @param arr
   */
  [types.SET_SYNC_PROGRESS_STEPS_TOTAL] (state, arr) {
    state.syncProgressStepsTotal = arr.length
    state.syncProgressSteps = 0
  },
  /**
   * Set "isLoggedIn" state
   * @param state
   * @param payload
   */
  [types.SET_LOGIN_STATE] (state, payload) {
    if (payload.reset) {
      resetState(state)
    }

    state.isLoggedIn = payload.value
    if (payload.value && state.stayLoggedIn) {
      localStorage.save('isLoggedIn', payload.value)
    }
  },
  /**
   * Set user profile stae
   * @param state
   * @param profile
   */
  [types.SET_PROFILE_STATE] (state, profile) {
    state.profile = profile
    if (profile && state.stayLoggedIn) {
      localStorage.save('profile', profile)
    }
  },
  /**
   * Set "stayLoggedIn" state
   * @param state
   * @param value
   */
  [types.SET_STAY_LOGGED_IN_STATE] (state, value) {
    state.stayLoggedIn = value
    localStorage.save('stayLoggedIn', value)
  },
  /**
   * Set "jwt" state
   * @param state
   * @param jwt
   */
  [types.SET_JWT_STATE] (state, jwt) {
    state.jwt = jwt
    if (state.stayLoggedIn) {
      localStorage.save('jwt', jwt)
    }
  },
  /**
   * Set "user" state
   * @param state
   * @param jwtClaims
   */
  [types.SET_USER_STATE] (state, jwtClaims) {
    state.user = {
      email: jwtClaims.email,
      username: jwtClaims.username
    }
    if (state.stayLoggedIn) {
      localStorage.save('user', state.user)
    }
  },
  /**
   * Save "Kontrolle" state in local storage
   */
  [types.SAVE_KONTROLLE_STATE] () {
    // console.log('SAVE_KONTROLLE_STATE')
    localStorage.save('kontrolle', Kontrolle.all())
  },
  /**
   * Save "HerdePreset" state in local storage
   */
  [types.SAVE_HERDE_PRESET_STATE] () {
    // console.log('SAVE_HERDE_PRESET_STATE')
    localStorage.save('herdePreset', HerdePreset.all())
  },
  /**
   * Save "Herde" state in local storage
   */
  [types.SAVE_HERDE_STATE] () {
    // console.log('SAVE_HERDE_STATE')
    localStorage.save('herde', Herde.all())
  },
  /**
   * Save "HerdeVorlage" state in local storage
   */
  [types.SAVE_HERDE_VORLAGE_STATE] () {
    // console.log('SAVE_HERDE_VORLAGE_STATE')
    localStorage.save('herdeVorlage', HerdeVorlage.all())
  },
  /**
   * Save "Antwort" state in local storage
   */
  [types.SAVE_ANTWORT_STATE] () {
    // console.log('SAVE_ANTWORT_STATE')
    localStorage.save('antwort', Antwort.all())
  },
  /**
   * Save "Tier" state in local storage
   */
  [types.SAVE_TIER_STATE] () {
    // console.log('SAVE_TIER_STATE')
    localStorage.save('tier', Tier.all())
  },
  /**
   * Set selected "Kontrolle" state
   * @param state
   * @param id
   */
  [types.SET_SELECTED_KONTROLLE_ID] (state, id) {
    console.log('SET_SELECTED_KONTROLLE_ID', id)
    state.kontrolleId = id
  },
  /**
   * Set current "Tier" state
   * @param state
   * @param tier
   */
  [types.SET_CURRENT_TIER] (state, tier) {
    state.tier = tier
  },
  /**
   * Set control info state
   * @param state
   * @param value
   */
  [types.SET_INFO_STATE] (state, value) {
    state.showInfoAgain = value
    localStorage.save('showInfoAgain', value)
  },
  /**
   * Set "selectedIndikatoren" state
   * @param state
   * @param value
   */
  [types.SET_SELECTED_INDIKATOREN] (state, value) {
    state.selectedIndikatoren = value
    localStorage.save('selectedIndikatoren', value)
  },
  /**
   * Set "selectedNKIndikatoren" state
   * @param state
   * @param value
   */
  [types.SET_SELECTED_NK_INDIKATOREN] (state, value) {
    state.selectedNKIndikatoren = value
  },
  /**
   * Set media file list & save in local storage
   * @param state
   * @param value
   */
  [types.SET_MEDIA_FILE_LIST] (state, value) {
    state.mediaFileList = value
    localStorage.save('mediaFileList', value)

    const now = getLocaleDateString()
    state.mediaFilesUpdatedAt = now
    localStorage.save('mediaFilesUpdatedAt', now)
  },
  /**
   * Media files loading state
   * @param state
   */
  [types.MEDIA_FILES_REQUEST] (state) {
    console.log('MEDIA_FILES_REQUEST')
    state.mediaFileStatus = 'loading'
  },
  /**
   * Media files success state
   * @param state
   */
  [types.MEDIA_FILES_SUCCESS] (state) {
    console.log('MEDIA_FILES_SUCCESS')
    state.mediaFileStatus = 'success'

    localStorage.save('datei', Datei.all())

    setTimeout(() => {
      state.mediaFileStatus = null
    }, 1000)
  },
  /**
   * Media file success
   * @param state
   * @param payload
   */
  [types.MEDIA_FILE_SUCCESS] (state, payload) {
    console.log('MEDIA_FILE_SUCCESS', payload)

    state.syncProgressSteps++

    const exists = Datei.query().where('name', payload.name).exists()
    if (exists) {
      Datei.update({
        where: (user) => {
          return user.name === payload.name
        },
        data: payload
      })
    } else {
      Datei.insert({
        data: payload
      })
    }
  },
  /**
   * Media files error state
   * @param state
   * @param payload
   */
  [types.MEDIA_FILES_ERROR] (state, payload) {
    console.log('MEDIA_FILES_ERROR', payload)
    state.mediaFileStatus = 'error'
  }
}

export default mutations
