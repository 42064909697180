import { Model } from '@vuex-orm/core'
import uuid from 'uuid'

export default class HerdeVorlage extends Model {
  static entity = 'herde-vorlagen'

  static fields () {
    return {
      id: this.uid(() => uuid()),
      titel: this.string(null).nullable(),
      beschreibung: this.string(null).nullable(),
      user_uuid: this.string(null).nullable(),
      name: this.string(''),
      haltungssystem: this.string(null).nullable(),
      leistungsgruppe: this.number(0),
      gesamt: this.number(null).nullable(),
      laktierend: this.number(null).nullable(),
      stichprobe: this.number(null).nullable(),
      fokusgruppe: this.number(null).nullable(),
      fokusgruppe_name: this.string('').nullable(),
      horntragend: this.number(0).nullable(),
      status: this.number(0).nullable(),
      synced: this.number(0).nullable(),
      benutzer_erstellt_am: this.string(null),
      benutzer_bearbeitet_am: this.string(null),
      benutzer_synchronisiert_am: this.string(null),
      produktionssystem_json: this.string(null).nullable(),
      erstellt_am: this.string(null).nullable(),
      bearbeitet_am: this.string(null).nullable()
    }
  }
}
