<template>
  <div class="herde-vorlagen">
    <b-row>
      <b-col cols="12">
        <h1>Herde Vorlagen</h1>

        <b-list-group v-if="herdeVorlagen.length" flush>
          <b-list-group-item v-for="herdeVorlage in herdeVorlagen" :key="herdeVorlage.name" :class="{'mb-3 border-0 rounded-lg': true }" variant="secondary">

            <div class="d-flex justify-content-between align-items-center">
              <h6>{{ herdeVorlage.name }}</h6>

              <b-button-group vertical>
                <b-btn class="btn-sm mt-0" variant="link" block @click="deleteHerdeVorlage(herdeVorlage)">
                  {{ messages.herdeVorlage.deleteHerdeVorlage }}
                </b-btn>

                <b-btn class="btn-sm mt-0" variant="link" block @click="openHerdeVorlageModal($event, herdeVorlage)">
                  {{ messages.herdeVorlage.updateHerdeVorlage }}
                </b-btn>

                <b-btn class="btn-sm mt-0" variant="link" block @click="pushHerdeVorlageToServer(herdeVorlage.id)" v-if="herdeVorlage.synced === 0">
                  {{ messages.herdeVorlage.sync }}
                </b-btn>
              </b-button-group>
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-col>

      <b-col cols="12">
        <b-btn class="mb-3 mt-1" block @click="openHerdeVorlageModal">
          <b-icon icon="plus" font-scale="1"></b-icon>
          {{ messages.herdeVorlage.newHerdeVorlage }}
        </b-btn>
      </b-col>
    </b-row>

    <b-modal id="herde-vorlage-modal"
             scrollable
             no-fade
             centered
             size="xl"
             no-close-on-esc
             no-close-on-backdrop
             :title="messages.herdeVorlage.herdeVorlage"
             modal-class="herde-modal"
             :ok-disabled="hasErrors"
             @ok="handleOk">

      <!-- Herde -->

      <b-row>
        <b-col>
          <h3>{{ messages.herde.headline }}</h3>
        </b-col>
      </b-row>

      <b-row v-if="model">
        <b-col cols="12" class="">
          <b-form-checkbox :value="1"
                           id="status"
                           :unchecked-value="0"
                           v-model="model.status">
            {{ messages.herde.status }}
          </b-form-checkbox>
        </b-col>

        <b-col class="mt-3">
          <b-form-group class="name">
            <label for="name">{{ messages.herde.name }}</label>
            <b-form-input v-model="model.name"
                          id="name"
                          type="text">
            </b-form-input>
            <b-form-invalid-feedback :state="$v.model.name.isUnique">
              <div v-if="!$v.model.name.isUnique">{{ messages.herde.nameUniqueError }}</div>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :state="$v.model.name.required">
              <div v-if="!$v.model.name.required">{{ messages.herde.nameRequiredError }}</div>
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group class="title">
            <label for="name">{{ messages.herde.title }}</label>
            <b-form-input v-model="model.titel"
                          id="titel"
                          type="text">
            </b-form-input>
          </b-form-group>

          <b-form-group class="description">
            <label for="name">{{ messages.herde.description }}</label>
            <b-form-textarea v-model="model.beschreibung"
                             id="beschreibung"
                          type="text">
            </b-form-textarea>
          </b-form-group>

          <b-form-group class="systems">
            <label class="d-inline">{{ messages.herde.system }}</label>
            <i class="small float-right">{{ messages.herde.systemHint }}</i>
            <b-form-radio-group v-model="model.haltungssystem"
                                id="haltungssystem"
                                :options="systems"
                                button-variant="outline-primary"
                                buttons>
            </b-form-radio-group>

            <b-form-invalid-feedback :state="$v.model.haltungssystem.required">
              {{ messages.herde.systemRequiredError }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col cols="12" class="mb-3">
          <b-form-checkbox :value="1"
                           :unchecked-value="0"
                           id="leistungsgruppe"
                           v-model="model.leistungsgruppe">
            {{ messages.herde.leistungsgruppe }}
          </b-form-checkbox>
        </b-col>

        <b-col cols="6" class="mb-3">
          <label for="gesamt">
            {{ messages.herde.gesamt }}
          </label>
          <b-form-input v-model.number="model.gesamt"
                        id="gesamt"
                        type="number">
          </b-form-input>
          <b-form-invalid-feedback :state="$v.model.gesamt.required">
            {{ messages.herde.gesamtError }}
          </b-form-invalid-feedback>
          <b-form-invalid-feedback :state="$v.model.gesamt.minValue">
            {{ messages.herde.gesamtError }}
          </b-form-invalid-feedback>
        </b-col>

        <b-col cols="6" class="mb-3">
          <label for="laktierend">
            {{ messages.herde.laktierend }}
          </label>
          <b-form-input id="laktierend"
                        v-model.number="model.laktierend"
                        type="number">
          </b-form-input>
          <b-form-invalid-feedback :state="$v.model.laktierend.required" >
            {{ messages.herde.laktierendRequiredError }}
          </b-form-invalid-feedback>
          <b-form-invalid-feedback :state="$v.model.laktierend.maxValue" >
            {{ messages.herde.laktierendMaxError }}
          </b-form-invalid-feedback>
          <b-form-invalid-feedback :state="$v.model.laktierend.minValue" >
            {{ messages.herde.laktierendMinError }}
          </b-form-invalid-feedback>
        </b-col>
      </b-row>

      <b-row v-if="showFokusgruppe">
        <b-col cols="6" class="mb-3">
          <label for="fokusgruppe">
            {{ messages.herde.fokusgruppe }}
          </label>
          <b-form-input id="fokusgruppe"
                        v-model.number="model.fokusgruppe"
                        type="number">
          </b-form-input>
          <b-form-invalid-feedback :state="$v.model.fokusgruppe.required">
            {{ messages.herde.fokusgruppeRequiredError }}
          </b-form-invalid-feedback>
          <b-form-invalid-feedback :state="$v.model.fokusgruppe.maxValue">
            {{ messages.herde.fokusgruppeMaxError }}
          </b-form-invalid-feedback>
          <b-form-invalid-feedback :state="$v.model.fokusgruppe.minValue" >
            {{ messages.herde.fokusgruppeMinError }}
          </b-form-invalid-feedback>
        </b-col>

        <b-col cols="6" class="mb-3">
          <label for="name">
            {{ messages.herde.fokusgruppeName }}
          </label>
          <b-form-input id="fokusgruppe-name"
                        v-model="model.fokusgruppe_name"
                        type="text">
          </b-form-input>
          <b-form-invalid-feedback :state="$v.model.fokusgruppe_name.required">
            {{ messages.herde.fokusgruppeNameError }}
          </b-form-invalid-feedback>
        </b-col>
      </b-row>

      <b-row v-if="model">
        <b-col cols="12">
          <b-form-checkbox :value="1"
                           :unchecked-value="0"
                           id="horntragend"
                           v-model="model.horntragend">
            {{ messages.herde.horntragend }}
          </b-form-checkbox>
        </b-col>
      </b-row>

      <br><br><br>

      <!-- Produktionssystem -->

      <div v-if="produktionssystemFragen.length" class="production-system-questions">
        <b-row>
          <b-col>
            <h3>{{ messages.produktionssystem.headline }}</h3>
          </b-col>
        </b-row>

        <b-row v-for="(frage, index) in produktionssystemFragen" :key="frage.id">
          <b-col>
            <div class="d-flex justify-content-between">
              <h6>{{ frage.text }}</h6>
            </div>

            <antwort :id="'psq-' + index" :data="frage" :standalone="true" ref="editors" @ready="ready"></antwort>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>

import HerdeVorlage from '@/store/modules/api/models/HerdeVorlage'
import { mapGetters } from 'vuex'
import Antwort from '@/components/kontrolle/Antwort.vue'
import uuid from 'uuid'
import {
  HALTUNGSSYSTEM_ANBINDESTALL,
  HALTUNGSSYSTEM_LAUFSTALL_FREIE_LIEGEFLAECHE,
  HALTUNGSSYSTEM_LAUFSTALL_LIEGEBOXEN
} from '@/store/utils'
import { integer, maxValue, minValue, required, requiredIf } from 'vuelidate/lib/validators'

export default {
  name: 'HerdeVorlagen',
  components: {
    Antwort
  },
  data () {
    return {
      model: null
    }
  },
  /**
   * Validation rules
   */
  validations () {
    return {
      model: {
        name: {
          required,
          isUnique: (value) => {
            const otherModels = this.herdeVorlagen.filter((vorlage) => {
              return vorlage.id !== this.model.id
            })

            return otherModels.every((vorlage) => {
              return vorlage.name !== value
            })
          }
        },
        haltungssystem: {
          required
        },
        gesamt: {
          required,
          minValue: minValue(1),
          maxValue: maxValue(9999),
          integer
        },
        laktierend: {
          required: requiredIf(() => {
            return (this.model && this.model.leistungsgruppe) || true
          }),
          minValue: minValue(1),
          maxValue: (value, vm) => {
            return (vm && value <= vm.gesamt)
          },
          integer
        },
        fokusgruppe: {
          required: requiredIf(() => {
            return this.showFokusgruppe
          }),
          minValue: (value) => {
            return !this.showFokusgruppe || value !== 0
          },
          maxValue: (value, vm) => {
            return (vm && value <= vm.gesamt)
          },
          integer
        },
        fokusgruppe_name: {
          required: requiredIf(() => {
            return this.showFokusgruppe
          })
        }
      }
    }
  },
  created () {
    this.$watch(vm => [vm.model], this.isValid, { deep: true })
  },
  computed: {
    herdeVorlagen () {
      return HerdeVorlage.all()
    },
    produktionssystemFragen () {
      if (this.model && this.model.haltungssystem) {
        return this.getProduktionssystemFragen(this.model.haltungssystem)
      }

      return []
    },
    ...mapGetters('help', [
      'messages'
    ]),
    ...mapGetters('api', [
      'jwtClaims'
    ]),
    /**
     * Herd management systems => "Haltungssystem"
     * @return {({text: string, state: boolean, value: string}|{text: string, state: boolean, value: string}|{text: string, state: boolean, value: string})[]}
     */
    systems () {
      return [
        { value: HALTUNGSSYSTEM_LAUFSTALL_LIEGEBOXEN, text: this.messages.herde.systemBox, state: false },
        { value: HALTUNGSSYSTEM_LAUFSTALL_FREIE_LIEGEFLAECHE, text: this.messages.herde.systemFree, state: false },
        { value: HALTUNGSSYSTEM_ANBINDESTALL, text: this.messages.herde.systemLashed, state: false }
      ]
    },
    /**
     * Show "Fokusgruppe" inputs
     * @return {boolean}
     */
    showFokusgruppe () {
      if (!this.model) {
        return false
      }

      return (this.model.leistungsgruppe)
    },
    /**
     * Tells if the forms has validation errors
     */
    hasErrors () {
      return this.$v.$error
    }
  },
  methods: {
    /**
     * Return production questions for the given haltungssystem
     * @param haltungssystem
     * @returns {*}
     */
    getProduktionssystemFragen (haltungssystem) {
      return this.$store.getters['api/getProduktionssystemFragenByHaltungssystem'](haltungssystem)
    },
    /**
     * Is view valid
     * @return {boolean}
     */
    isValid () {
      this.$v.$touch()
      return !this.$v.$error
    },
    /**
     * Opens the HerdeVorlage modal form and set the model property
     * if the form should start in edit mode.
     */
    openHerdeVorlageModal (event, herdeVorlage = null) {
      if (herdeVorlage) {
        this.model = herdeVorlage
      } else {
        this.model = new HerdeVorlage()
        const id = uuid()
        this.model.$id = id
        this.model.id = id
        this.model.user_uuid = this.jwtClaims.uuid
      }

      this.$bvModal.show('herde-vorlage-modal')
    },
    /**
     * If in edit mode the property model will be set,
     * and it's value will be used as the initial value for this editor
     * @param antwort
     */
    ready (antwort) {
      if (this.model) {
        let produktionssystemJson = JSON.parse(this.model.produktionssystem_json)

        if (!produktionssystemJson) {
          produktionssystemJson = {}
        }

        const frageId = antwort.data.id
        const antwortValue = produktionssystemJson[frageId]
        antwort.setValueAndChange(antwortValue)
      }
    },
    /**
     * Handle function for when modal "Ok" button is clicked.
     * Will prevent the modal closing and call the handleSubmit methods
     * @param bvModalEvent
     */
    handleOk (bvModalEvent) {
      bvModalEvent.preventDefault()
      this.handleSubmit()
    },
    /**
     * Validates and collects Herde and Produktionssystem information from modal form
     * and saves it in store and localstorage.
     * Closes the modal if everything went right.
     */
    handleSubmit () {
      if (!this.isValid()) {
        console.log('not valid')
        return
      }

      let produktionssystemJson = {}

      const refEditors = this.$refs['editors']

      for (let i = 0; i < Object.values(refEditors).length; i++) {
        const antwort = Object.values(refEditors)[i]
        const frageId = antwort.data.id
        produktionssystemJson[frageId] = antwort.getValue()
      }

      this.model.produktionssystem_json = JSON.stringify(produktionssystemJson)
      this.model.synced = 0
      this.$store.dispatch('api/insertOrUpdateHerdeVorlage', this.model)

      this.$nextTick(() => {
        this.$bvModal.hide('herde-vorlage-modal')
      })
    },
    /**
     * Deletes a HerdeVorlage from store and localstorage
     * @param herdeVorlage
     */
    deleteHerdeVorlage (herdeVorlage) {
      this.$store.dispatch('api/deleteHerdeVorlage', herdeVorlage)
    },
    /**
     * Pushes the herdeVorlage with the given id to the server
     * @param herdeVorlageId
     */
    pushHerdeVorlageToServer (herdeVorlageId) {
      this.$store.dispatch('api/pushHerdeVorlage', herdeVorlageId)
    }
  }
}
</script>
