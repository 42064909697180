<template>
  <div class="antwort">
    <div :ref="data.id"></div>
  </div>
</template>

<script>
import { JSONEditor } from '@json-editor/json-editor/dist/jsoneditor.js'
import Indikator from '@/store/modules/api/models/Indikator'
import Antwort from '@/store/modules/api/models/Antwort'
import Info from '@/store/modules/api/models/Info'
import uuid from 'uuid'
import { mapGetters, mapState } from 'vuex'
import {
  FRAGE_UUID_AUSWEICHDISTANZ,
  FRAGE_UUID_BELEGUNG_LAKTIERENDE_TIERE,
  FRAGE_UUID_BELEGUNG_LEISTUNGSGRUPPE,
  FRAGE_UUID_BELEGUNG_TROCKENSTEHER,
  FRAGE_UUID_HITZESTRESS,
  HALTUNGSSYSTEM_ANBINDESTALL
} from '@/store/utils'
import {
  EventBus,
  EVENT_ON_JSONEDITOR_CHANGE
} from '@/event-bus'

import mixins from '@/mixins'
import {
  massnahmenvorschlag,
  geburtenUndLanglebigkeit,
  liegeUndSteheverhaltenLiegeboxen,
  liegeUndSteheverhaltenFreieflaeche,
  liegeUndSteheverhaltenAnbindestall
} from '@/components/kontrolle/validators'

export default {
  name: 'Antwort',
  mixins: [mixins],
  props: {
    data: {
      type: Object
    },
    lazy: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    massnahmeId: {
      type: String,
      default: null
    },
    standalone: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      jsonEditorElement: null,
      jsonEditor: null,
      prefill: false,
      model: {},
      info: {}
    }
  },
  mounted () {
    if (!this.lazy) {
      this.init()
    }
    this.$root.$on('bv::modal::hidden', () => {
      this.info = {}
    })
  },
  activated () {
    if (!this.lazy) {
      this.init()
    }
  },
  watch: {
    'model.json' (newValue, oldValue) {
      this.resolveDependingInputValues(newValue, oldValue)
    }
  },
  computed: {
    /**
     * Map api getters
     */
    ...mapGetters('api', [
      'herde'
    ]),
    /**
     * Map api module state
     */
    ...mapState('api', [
      'tier'
    ])
  },
  methods: {
    /**
     * Initialize component
     */
    init () {
      this.initModel()
      this.initJsonEditor()
    },
    /**
     * Initialize "Antwort" model
     */
    initModel () {
      if (this.prefill) {
        const lastModel = this.getLastAntwortByFrageId()
        const model = this.getAntwort() || this.getNewAntwort()
        if (lastModel && lastModel.json) {
          model.json = lastModel.json
        }
        this.model = model
      } else {
        this.model = this.getAntwort() || this.getNewAntwort()
      }
    },
    /**
     * Get "Antwort" by component properties
     * @return {Item<InstanceOf<Antwort>>}
     */
    getAntwort () {
      if (this.kontrolle) {
        return Antwort.query()
          .where('kontrolle_id', this.kontrolle.id)
          .where('frage_id', this.data.id)
          .where('massnahme_id', this.massnahmeId)
          .where('tier_id', (this.tier) ? this.tier.id : null)
          .first()
      } else {
        return Antwort.query()
          .where('frage_id', this.data.id)
          .where('massnahme_id', this.massnahmeId)
          .where('tier_id', (this.tier) ? this.tier.id : null)
          .first()
      }
    },
    /**
     * Get new "Antwort" model
     * @return {Antwort}
     */
    getNewAntwort () {
      if (this.kontrolle) {
        return new Antwort({
          id: uuid(),
          frage_id: this.data.id,
          massnahme_id: this.massnahmeId,
          kontrolle_id: this.kontrolle.id,
          tier_id: null,
          json: null
        })
      } else {
        return new Antwort({
          id: uuid(),
          frage_id: this.data.id,
          massnahme_id: this.massnahmeId,
          tier_id: null,
          json: null
        })
      }
    },
    /**
     * Get last inserted "Antwort" model by "frage_id"
     * @return {Item<InstanceOf<Antwort>>}
     */
    getLastAntwortByFrageId () {
      return Antwort.query()
        .where('frage_id', this.data.id)
        .orderBy('bearbeitet_am', 'desc')
        .first()
    },
    /**
     * Use last "Antwort" model
     */
    useLastFrageAntwortModel () {
      this.prefill = true
      this.init()
    },
    /**
     * Initialize JSON editor instance
     */
    initJsonEditor (registerReady = true) {
      if (!this.standalone && !this.kontrolle) {
        return false
      }

      if (this.jsonEditor) {
        this.jsonEditor.destroy()
      }

      let schema = JSON.parse(this.data.antwort_schema)
      if (this.data.id === FRAGE_UUID_AUSWEICHDISTANZ) {
        schema = this.updateSchema(schema)
      }

      const id = this.massnahmeId ? this.massnahmeId : this.data.id

      this.jsonEditorElement = this.$refs[this.data.id]
      this.jsonEditor = new JSONEditor(this.jsonEditorElement, {
        schema: schema,
        theme: 'bootstrap4',
        disable_collapse: true,
        disable_edit_json: true,
        disable_properties: true,
        use_default_values: false,
        required_by_default: true,
        show_errors: 'always',
        disable_array_reorder: true,
        disable_array_delete_all_rows: true,
        disable_array_delete_last_row: true,
        keep_oneof_values: false,
        no_additional_properties: true,
        form_name_root: 'form-' + id
      })

      this.setValue()

      if (this.disabled) {
        this.disable()
      }

      this.jsonEditor.on('change', this.onChange)

      if (registerReady) {
        this.jsonEditor.on('ready', this.onReady)
      }

      JSONEditor.defaults.languages.de = {
        error_type: this.messages.antwort.errorType,
        error_minLength: this.messages.antwort.minLength,
        error_minimum_incl: this.messages.antwort.errorMinimumIncl,
        error_maximum_incl: this.messages.antwort.errorMaximumIncl,
        error_notempty: this.messages.antwort.errorType,
        error_notset: this.messages.antwort.errorType,
        error_oneOf: this.messages.antwort.errorOneOf,
        error_date_before: this.messages.antwort.errorDateBefore,
        error_date_before_today: this.messages.antwort.errorDateBeforeToday,
        button_delete_node_warning: this.messages.antwort.deleteWarning,
        button_add_row_title: this.messages.antwort.buttonAddRowTitle,
        button_delete_row_title: this.messages.antwort.buttonDeleteRowTitle,
        sumLessOrEqualThanStichprobeLiegeboxen: this.messages.antwort.sumLessOrEqualThanStichprobeLiegeboxen,
        sumLessOrEqualThanStichprobeAnbindestallFreieflaeche: this.messages.antwort.sumLessOrEqualThanStichprobeAnbindestallFreieflaeche,
        greaterOrEqualThanGesamt: this.messages.antwort.greaterOrEqualThanGesamt,
        lessOrEqualThanGesamt: this.messages.antwort.lessOrEqualThanGesamt,
        lessOrEqualThanGeburten: this.messages.antwort.lessOrEqualThanGeburten,
        lessOrEqualThanVerendet: this.messages.antwort.lessOrEqualThanVerendet
      }

      JSONEditor.defaults.language = 'de'
      JSONEditor.defaults.proq = {
        herde: this.herde,
        kontrolle: this.kontrolle
      }
      JSONEditor.defaults.custom_validators[0] = massnahmenvorschlag
      JSONEditor.defaults.custom_validators[1] = geburtenUndLanglebigkeit
      JSONEditor.defaults.custom_validators[2] = liegeUndSteheverhaltenLiegeboxen
      JSONEditor.defaults.custom_validators[3] = liegeUndSteheverhaltenFreieflaeche
      JSONEditor.defaults.custom_validators[4] = liegeUndSteheverhaltenAnbindestall
    },
    /**
     * Set JSON editor value & disable state
     */
    setValue () {
      if (!this.model) {
        return false
      }

      const value = JSON.parse(this.model.json)
      this.jsonEditor.setValue(value)

      const id = (this.tier !== null) ? this.tier.id : Indikator.find(this.data.indikator_id).indikator_gruppe_id
      if (this.kontrolle && this.kontrolle.finished.includes(id)) {
        this.jsonEditor.disable()
      }
    },
    /**
     * Set JSON editor value
     */
    setValueAndChange (json) {
      this.model = new Antwort({
        id: uuid(),
        frage_id: this.data.id,
        massnahme_id: this.massnahmeId,
        tier_id: null,
        json: JSON.stringify(json)
      })

      this.initJsonEditor(false)
    },
    /**
     * Json-Editor change handler
     */
    onChange () {
      EventBus.$emit(EVENT_ON_JSONEDITOR_CHANGE, {})
      this.setActiveState()

      if (!this.model) {
        return false
      }

      this.model.json = JSON.stringify(this.jsonEditor.getValue())
      this.model.tier_id = (this.tier) ? this.tier.id : null

      let allowDisabledEditor = false
      allowDisabledEditor = this.extraChangeLogic()

      if (this.isValid() && (this.jsonEditor.isEnabled() || allowDisabledEditor)) {
        if (this.standalone) {
          return
        }
        this.$store.dispatch('api/insertOrUpdateAntwort', this.model)
      }
    },
    /**
     * Json-Editor ready handler
     */
    onReady () {
      this.setActiveState()
      this.setMinDateToday()

      if (this.data.antwort_schema) {
        const schema = JSON.parse(this.data.antwort_schema)
        this.addInfoBtnBySchemaProperty(schema)
      }

      this.$emit('ready', this)
    },
    /**
     * Extra change logic for "Hitzestress" indikator
     * TODO: implement custom validator
     */
    extraChangeLogic () {
      // when 10% of the cows have heat stress the form will be disabled and a
      // popup should appear
      if (this.data.id === FRAGE_UUID_HITZESTRESS) {
        const value = this.jsonEditor.getValue()
        let total = this.herde.stichprobe
        let hot = 0
        let percent = 0

        if (value.kuehegroups && value.kuehegroups.length) {
          value.kuehegroups.forEach((group, index) => {
            let withValues = 0
            for (let key in group) {
              if (group.hasOwnProperty(key)) {
                if (group[key] === 'J') {
                  hot++
                }
                if (group[key] === 'J' || group[key] === 'N') {
                  withValues++
                }
              }
            }

            percent = (hot / total * 100)

            if (percent >= 10) {
              this.jsonEditor.disable()
              this.showAlertModal(this.messages.alerts.heatstress, this.messages.alerts.heatstressMsg, this.messages.alerts.close, 'lg')
            } else if (index === this.jsonEditor.getValue().kuehegroups.length - 1 && withValues === 5) {
              const kuehegroupsPath = this.jsonEditor.options.form_name_root + '.kuehegroups'
              const addGroupBtn = document.querySelector('[data-schemapath="' + kuehegroupsPath + '"] .json-editor-btn-add')
              addGroupBtn.click()
            }
          })
        }
        return true
      }
    },
    /**
     * Get Json-Editor value
     * @return {}
     */
    getValue () {
      return this.jsonEditor.getValue()
    },
    /**
     * Is Json-Editor valid
     * @return {boolean}
     */
    isValid () {
      const errors = this.jsonEditor.validate()
      if (errors.length) {
        // console.error('JSONEDITOR ERRORS...', errors)
      }
      return !errors.length
    },
    /**
     * Disable entire Json-Editor form
     */
    disable () {
      this.jsonEditor.disable()
    },
    /**
     * Enable entire Json-Editor form
     */
    enable () {
      this.jsonEditor.enable()
    },
    /**
     * Set the radio input label state
     * @desc if the radio group is selected, remove the "active" css class of the next sibling label element.
     * Add the "active" css class to the currently selected next sibling label element of the radio input.
     */
    setActiveState () {
      const radioGroup = document.querySelectorAll('div[data-container-for="radio"]')
      radioGroup.forEach(container => {
        const radios = container.querySelectorAll('input[type="radio"]')
        if (radios.length) {
          radios.forEach(radio => {
            radio.nextElementSibling.classList.remove('active')
          })
        }
        const checkedEl = container.querySelector('input[type="radio"]:checked')
        if (checkedEl) {
          const label = checkedEl.nextElementSibling
          label.classList.add('active')
        }
      })
    },
    /**
     * Set today as min date in date inputs
     */
    setMinDateToday () {
      const today = new Date().toISOString().split('T')[0]
      const dateInputs = [
        ...document.querySelectorAll('[id$="[umsetzung_bis]"]'),
        ...document.querySelectorAll('[id$="[nachkontrolle_bis]"]')
      ]
      dateInputs.forEach((input) => {
        input.setAttribute('min', today)
      })
    },
    /**
     * Add info buttons depending on schema properties
     * @param schema
     */
    addInfoBtnBySchemaProperty (schema) {
      // console.log('ADD_PROPERTIES_INFO', schema)
      const properties = schema.properties
      if (properties) {
        Object.entries(properties).forEach(property => {
          const [key, value] = property
          if (value.items) {
            this.appendToArrayItemLabel(value.items.properties)
          } else {
            this.appendToLabel(key, value)
          }
        })
      }

      const oneOf = schema.oneOf
      if (oneOf) {
        oneOf.forEach(object => {
          if (object.properties) {
            Object.entries(object.properties).forEach(property => {
              const [key, value] = property
              this.appendToLabel(key, value)
            })
          }
        })
      }

      const items = schema.items
      if (items && items.properties) {
        this.appendToArrayItemLabel(items.properties)
      }

      if (this.data.infos && schema.type === 'string') {
        this.data.infos.forEach(info => {
          this.appendToLabel(null, info)
        })
      }
    },
    /**
     * Append for each label if info is set
     * @param key
     * @param value
     */
    appendToLabel (key, value) {
      const info = (value instanceof Info) ? value : this.getInfo(value)
      if (info) {
        const labelSelector = (key === null)
          ? 'div[data-schemapath="' + this.jsonEditor.options.form_name_root + '"] label'
          : 'div[data-schemapath="' + this.jsonEditor.options.form_name_root + '.' + key + '"] label'

        const label = document.querySelector(`${labelSelector}`)
        const hasButton = document.querySelector(`${labelSelector} button`)
        if (hasButton === null) {
          this.appendInfoButton(label, info)
        }
      }
    },
    /**
     * Append for each array item label if info is set
     * @param properties
     */
    appendToArrayItemLabel (properties) {
      for (const property in properties) {
        const info = this.getInfo(properties[property])
        if (info) {
          const nodes = document.querySelectorAll('div[data-schemapath$="' + property + '"]')
          if (nodes.length) {
            [...nodes]
              .filter(node => node.dataset.schemapath.match(/.\d{1,}.[a-z-_]+/g))
              .forEach(element => {
                const label = element.querySelector('label')
                const hasButton = element.querySelector('label button')
                if (hasButton === null) {
                  this.appendInfoButton(label, info)
                }
              })
          }
        }
      }
    },
    /**
     * Get info object
     * @param property
     * @return {Object|boolean}
     */
    getInfo (property) {
      return (property.options && property.options.info) ? property.options.info : false
    },
    /**
     * Append info button
     * @param el
     * @param infoUuid
     */
    appendInfoButton (el, infoUuid) {
      // console.log(property, infoUuid)
      if (el) {
        const icon = document.createElement('i')
        icon.classList.add('icon-circle-info')

        const btn = document.createElement('button')
        btn.classList.add('btn', 'btn-link')
        btn.onclick = () => {
          this.openInfoModal(infoUuid)
        }

        btn.appendChild(icon)
        el.appendChild(btn)
      }
    },
    /**
     * Update schema values
     * @param schema
     * @return {Object}
     */
    updateSchema (schema) {
      return Object.assign(schema, {
        minItems: this.herde.stichprobe,
        maxItems: this.herde.stichprobe
      })
    },
    /**
     * Resolve depending input values
     * @param newValue
     * @param oldValue
     */
    resolveDependingInputValues (newValue, oldValue) {
      const after = (newValue) ? JSON.parse(newValue) : null
      const before = (oldValue) ? JSON.parse(oldValue) : null
      if (this.data.id === FRAGE_UUID_BELEGUNG_TROCKENSTEHER ||
          this.data.id === FRAGE_UUID_BELEGUNG_LEISTUNGSGRUPPE ||
          this.data.id === FRAGE_UUID_BELEGUNG_LAKTIERENDE_TIERE) {
        if (after && before) {
          if (Array.isArray(after) && Array.isArray(before)) {
            for (let i in after) {
              this.showInputNotice(after[i], before[i])
            }
          } else {
            this.showInputNotice(after, before)
          }
        }
      }
    },
    /**
     * Show input notice, if "anz_liege|_tr|_lak" === "anz_fress|_tr|_lak" depending on "Haltungssystem"
     * @param newValue
     * @param oldValue
     * @return {boolean}
     */
    showInputNotice (newValue, oldValue) {
      const system = (this.herde && this.herde.haltungssystem) ? this.herde.haltungssystem : null
      const anzLiegeAndFressIsEqual = (newValue.anz_liege && newValue.anz_fress && newValue.anz_liege === newValue.anz_fress) ||
                                      (newValue.anz_liege_tr && newValue.anz_fress_tr && newValue.anz_liege_tr === newValue.anz_fress_tr) ||
                                      (newValue.anz_liege_lak && newValue.anz_fress_lak && newValue.anz_liege_lak === newValue.anz_fress_lak)

      if (anzLiegeAndFressIsEqual && system === HALTUNGSSYSTEM_ANBINDESTALL) {
        this.showAlertModal(this.messages.antwort.inputNoticeTitle, this.messages.antwort.inputNoticeText, this.messages.antwort.inputNoticeButton)
      }
    }
  }
}
</script>
