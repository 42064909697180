import Vue from 'vue'
import Router from 'vue-router'
import Store from './store'

// import Home from './views/Home.vue'
import HerdeVorlagen from './views/HerdeVorlagen.vue'
import Kontrollen from './views/Kontrollen.vue'
import Kontrolle from './views/Kontrolle.vue'
import Ergebnisse from './views/Ergebnisse.vue'
import Produktionssystem from './views/Produktionssystem.vue'
import Ursachenanalyse from './views/Ursachenanalyse.vue'
import Massnahmenvorschlaege from './views/Massnahmenvorschlaege.vue'
import Massnahmenplan from './views/Massnahmenplan.vue'
import Login from './views/Login.vue'
import Einstellungen from './views/Einstellungen.vue'

import {
  CONTROL_STATE_FINISHED,
  CONTROL_TYPE_STATIC,
  STATUS_SYNC_MASSNAHMENVORSCHLAEGE_FINISHED,
  STATUS_SYNC_PRODUKTIONSSYSTEM_FINISHED,
  STATUS_SYNC_URSACHENANALYSE_APP_FINISHED
} from '@/store/utils'

import { APP_MODE_APP } from '@/configuration'

Vue.use(Router)

/**
 * Is authenticated, global "beforeEnter" guard
 * @param to
 * @param from
 * @param next
 */
const isAuthenticated = (to, from, next) => {
  if (!Store.getters['api/isAuthenticated']) {
    next('/')
  } else {
    next()
  }
}

/**
 * Returns true if the state of all kontrolle indicators are equal to 'success' (green)
 * @return boolean
 */
const controlStateSuccess = () => {
  const kontrolle = Store.getters['api/kontrolle']
  if (!kontrolle) {
    return false
  }

  return kontrolle.ergebnisse
    .map(ergebnis => ergebnis.state)
    .every(state => state === 'success' || state === 'light')
}

/**
 * Global "afterEach" router guard
 * @return {Promise<void>}
 */
const afterEachRouterGuard = async () => {
  Vue.prototype.$eventHub.$emit('DATA_PROCESS_EVENT', false)
}

/**
 * Global "beforeEach" router guard
 * @param to
 * @param from
 * @param next
 * @return {Promise<void>}
 */
const beforeEachRouterGuard = async (to, from, next) => {
  if (to.name === 'produktionssystem' ||
      to.name === 'ursachenanalyse' ||
      to.name === 'massnahmenvorschlaege' ||
      to.name === 'massnahmenplan') {
    Vue.prototype.$eventHub.$emit('DATA_PROCESS_EVENT', true)
  }

  const kontrolle = Store.getters['api/kontrolle']
  const showAlertModal = window.app.showAlertModal
  const messages = Store.getters['help/messages']
  const alertModalTitle = messages.alerts.routerGuardTitle

  if ((kontrolle === null || kontrolle.status !== CONTROL_STATE_FINISHED) &&
      (to.name === 'ergebnisse' ||
       to.name === 'produktionssystem' ||
       to.name === 'ursachenanalyse' ||
       to.name === 'massnahmenvorschlaege' ||
       to.name === 'massnahmenplan')) {
    showAlertModal(alertModalTitle, messages.alerts.routerGuardTextControlMissing)
    next(false)
  } else if ((kontrolle !== null && kontrolle.typ === CONTROL_TYPE_STATIC) &&
             (to.name === 'produktionssystem' ||
              to.name === 'ursachenanalyse' ||
              to.name === 'massnahmenvorschlaege' ||
              to.name === 'massnahmenplan')) {
    showAlertModal(alertModalTitle, messages.alerts.routerGuardTextControlStatic)
  } else if ((kontrolle !== null && kontrolle.hauptkontrolle_id === null) &&
             (to.name === 'produktionssystem' ||
              to.name === 'ursachenanalyse' ||
              to.name === 'massnahmenvorschlaege' ||
              to.name === 'massnahmenplan') &&
              controlStateSuccess()) {
    showAlertModal(alertModalTitle, messages.global.positiveResults)
  } else if ((kontrolle !== null && kontrolle.hauptkontrolle_id === null && kontrolle.status_sync < STATUS_SYNC_PRODUKTIONSSYSTEM_FINISHED) &&
             (to.name === 'ursachenanalyse' ||
              to.name === 'massnahmenvorschlaege' ||
              to.name === 'massnahmenplan')) {
    showAlertModal(alertModalTitle, messages.alerts.routerGuardTextProduktionssystem)
  } else if ((kontrolle !== null && kontrolle.hauptkontrolle_id === null && kontrolle.status_sync < STATUS_SYNC_URSACHENANALYSE_APP_FINISHED) &&
             (to.name === 'massnahmenvorschlaege' ||
              to.name === 'massnahmenplan')) {
    const text = (Store.state.appMode === APP_MODE_APP)
      ? messages.alerts.routerGuardTextUrsachenanalyse
      : messages.alerts.routerGuardTextUrsachenanalyseDesktop

    showAlertModal(alertModalTitle, text)
  } else if ((kontrolle !== null && kontrolle.hauptkontrolle_id === null && kontrolle.status_sync < STATUS_SYNC_MASSNAHMENVORSCHLAEGE_FINISHED) &&
             (to.name === 'massnahmenplan')) {
    showAlertModal(alertModalTitle, messages.alerts.routerGuardTextMassnahmevorschlaege)
  } else {
    // Disable routing for specific routes if control is "Nachkontrolle"
    if ((kontrolle !== null && kontrolle.hauptkontrolle_id !== null) &&
        (to.name === 'produktionssystem' ||
         to.name === 'ursachenanalyse' ||
         to.name === 'massnahmenvorschlaege' ||
         to.name === 'massnahmenplan')) {
      showAlertModal(alertModalTitle, messages.alerts.routerGuardTextNachkontrolle)
    } else {
      next()
    }
  }
}

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'login',
      component: Login,
      meta: {
        title: 'Login',
        icon: ''
      },
      beforeEnter: (to, from, next) => {
        if (Store.getters['api/isAuthenticated']) {
          next('/kontrollen')
        } else {
          next()
        }
      }
    }, /* ,
    {
      path: '/home',
      name: 'home',
      component: Home,
      meta: {
        title: 'Home',
        icon: 'icon-home'
      }
    }, */
    {
      path: '/herdevorlagen',
      name: 'herdevorlagen',
      component: HerdeVorlagen,
      meta: {
        title: 'Kontrollen',
        icon: 'icon-cow'
      },
      beforeEnter: isAuthenticated
    },
    {
      path: '/kontrollen',
      name: 'kontrollen',
      component: Kontrollen,
      meta: {
        title: 'Kontrollen',
        icon: 'icon-cow'
      }
    },
    {
      path: '/kontrolle',
      name: 'kontrolle',
      component: Kontrolle,
      meta: {
        title: 'Kontrolle',
        icon: ''
      },
      props: true
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      // component: () => import(/!* webpackChunkName: "about" *!/ './views/About.vue')
    },
    {
      path: '/ergebnisse',
      name: 'ergebnisse',
      component: Ergebnisse,
      meta: {
        title: 'Ergebnisse',
        icon: 'icon-graph'
      }
    },
    {
      path: '/produktionssystem',
      name: 'produktionssystem',
      component: Produktionssystem,
      meta: {
        title: 'Produktionssystem',
        titleShort: 'produktionssystem',
        icon: 'icon-barn'
      },
      beforeEnter: isAuthenticated
    },
    {
      path: '/ursachenanalyse',
      name: 'ursachenanalyse',
      component: Ursachenanalyse,
      meta: {
        title: 'Ursachenanalyse',
        titleShort: 'Analyse',
        icon: 'icon-analysis'
      },
      beforeEnter: isAuthenticated
    },
    {
      path: '/massnahmenvorschlaege',
      name: 'massnahmenvorschlaege',
      component: Massnahmenvorschlaege,
      meta: {
        title: 'Maßnahmenvorschläge',
        titleShort: 'Maßnahmenvorschläge',
        icon: 'icon-measures'
      },
      beforeEnter: isAuthenticated
    },
    {
      path: '/massnahmenplan',
      name: 'massnahmenplan',
      component: Massnahmenplan,
      meta: {
        title: 'Maßnahmenplan',
        titleShort: 'Maßnahmenplan',
        icon: 'icon-circle-checkmark'
      },
      beforeEnter: isAuthenticated
    },
    {
      path: '/einstellungen',
      name: 'einstellungen',
      component: Einstellungen,
      meta: {
        title: 'Einstellungen',
        icon: 'three-dots-vertical'
      }
    }
  ]
})

router.beforeEach(beforeEachRouterGuard)
router.afterEach(afterEachRouterGuard)

export default router
