<template>
  <b-modal :id="id"
     no-fade
     centered
     size="sm"
     hide-header
     no-close-on-esc
     no-close-on-backdrop
     modal-class="herde-vorlagen-modal">

    <h6 class="text-center">
      {{ messages.herde.change }}
    </h6>

    <p class="text-center my-2">
      {{ messages.herde.changeText }}
    </p>

    <b-form-group class="herde-options" v-if="herdeVorlagen">
      <b-form-radio v-for="option in herdeVorlagen"
                    :key="option.id"
                    v-model="herdeVorlage"
                    :value="option">
        {{ option.name }}
      </b-form-radio>
    </b-form-group>

    <template v-slot:modal-footer="{ close }">
      <b-btn variant="link" @click="setHerdeVorlage">
        {{ messages.herde.choose }}
      </b-btn>
    </template>
  </b-modal>
</template>

<script>
import mixins from '@/mixins'
import { mapGetters } from 'vuex'
import HerdeVorlage from '@/store/modules/api/models/HerdeVorlage'

export default {
  name: 'HerdeVorlagenModal',
  mixins: [mixins],
  props: {
    id: {
      type: String,
      default: 'herde-vorlagen-modal'
    }
  },
  data () {
    return {
      herdeVorlage: null
    }
  },
  computed: {
    /**
     * Map help getters
     */
    ...mapGetters('help', [
      'messages'
    ]),
    /**
     * All Herdevorlage filtered by status
     */
    herdeVorlagen () {
      let herdeVorlagen = HerdeVorlage.all()

      herdeVorlagen = herdeVorlagen.filter((herdeVorlage) => {
        return herdeVorlage.status === 1
      })

      return herdeVorlagen
    }
  },
  methods: {
    setHerdeVorlage () {
      this.$emit('set-preset', this.herdeVorlage)
    }
  }
}
</script>
